import React from 'react';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  element: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const isAuthenticated = localStorage.getItem('loginKey') === '11112222';
  
  return isAuthenticated ? element : <Navigate to="/login" />;
};

export default ProtectedRoute;
