import React from 'react';
import { Box } from '@mui/material';
import { useDropzone, DropzoneOptions } from 'react-dropzone';

interface Props {
  onDrop: (acceptedFiles: File[]) => void;
  filePreview?: string | null; // Optional preview URL for the file
  removeFile?: () => void; // Optional function to remove the file
}

const ContentDropzone: React.FC<Props> = ({ onDrop, filePreview, removeFile }) => {
  const dropzoneOptions: DropzoneOptions = {
    onDrop,
    multiple: false,
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone(dropzoneOptions);

  return (
    <Box {...getRootProps()} style={{ border: '2px dashed #ccc', padding: '16px', textAlign: 'center', flex: 1 }}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the file here...</p>
      ) : (
        <p>Drag 'n' drop a file here, or click to select a file</p>
      )}
      {filePreview && (
        <div>
          <img src={filePreview} alt="Preview" style={{ maxWidth: '100%', marginTop: '8px' }} />
          {removeFile && (
            <button onClick={removeFile} style={{ marginTop: '8px' }}>
              Remove File
            </button>
          )}
        </div>
      )}
    </Box>
  );
};

export default ContentDropzone;
