import React, { Suspense } from 'react';
import backgroundimage from '../images/background_image_one.webp';


import cockroachicon from '../images/cockroach.png';

const LazyHomeBanner = React.lazy(() => import('./homebanner'));

const LazyHomeBannerWrapper = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <LazyHomeBanner backgroundimage={backgroundimage} cockroachicon={cockroachicon} />
  </Suspense>
);

export default LazyHomeBannerWrapper;
