import React from 'react';
import { Container, Typography, Grid, Box, Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
import cer1 from "../trainninggallery/tg15.jpg";
import cer2 from "../trainninggallery/tg12.jpg";
import cer3 from "..//trainninggallery/tg13.jpg";

const TraininggalleryMain: React.FC = () => {
  return (
    <Box sx={{ backgroundColor: '#f7f7f7', padding: '2rem 0' }}>
      <Container maxWidth="lg" sx={{ padding: '2rem', textAlign: 'center', backgroundColor: '#fff', borderRadius: '16px', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)' }}>
       
        <Typography variant="h4" sx={{ marginBottom: '2rem', fontWeight: 'bold' }}>
          Gallery
        </Typography>

        
        <Grid container spacing={4} justifyContent="center">
         
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                border: '2px solid #e0e0e0',
                borderRadius: '12px',
                padding: '1rem',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                backgroundColor: '#fff',
                '&:hover': { boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)' },
              }}
            >
              <Box
                component="img"
                src={cer1}
                alt="PDF 1"
                sx={{
                  width: '100%',
                  height: '200px',
                  objectFit: 'cover',
                  marginBottom: '1rem',
                  borderRadius: '8px',
                }}
              />
              <Typography variant="h6" sx={{ marginBottom: '1rem', fontWeight: 'bold' }}>
              Training Session
              </Typography>
            </Box>
          </Grid>

          
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                border: '2px solid #e0e0e0',
                borderRadius: '12px',
                padding: '1rem',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                backgroundColor: '#fff',
                '&:hover': { boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)' },
              }}
            >
              <Box
                component="img"
                src={cer2}
                alt="PDF 2"
                sx={{
                  width: '100%',
                  height: '200px',
                  objectFit: 'cover',
                  marginBottom: '1rem',
                  borderRadius: '8px',
                }}
              />
              <Typography variant="h6" sx={{ marginBottom: '1rem', fontWeight: 'bold' }}>
              Training Session
              </Typography>
            </Box>
          </Grid>

          
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                border: '2px solid #e0e0e0',
                borderRadius: '12px',
                padding: '1rem',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                backgroundColor: '#fff',
                '&:hover': { boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)' },
              }}
            >
              <Box
                component="img"
                src={cer3}
                alt="PDF 3"
                sx={{
                  width: '100%',
                  height: '200px',
                  objectFit: 'cover',
                  marginBottom: '1rem',
                  borderRadius: '8px',
                }}
              />
              <Typography variant="h6" sx={{ marginBottom: '1rem', fontWeight: 'bold' }}>
              Training Session
              </Typography>
            </Box>
          </Grid>
        </Grid>

        
        <Box sx={{ marginTop: '2rem' }}>
          <NavLink to="/traininggallery" style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="primary" sx={{ fontWeight: 'bold', padding: '0.75rem 2rem' }}>
              View More
            </Button>
          </NavLink>
        </Box>
      </Container>
    </Box>
  );
};

export default TraininggalleryMain;
