import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import PestControlIcon from '@mui/icons-material/PestControl';
import { Link } from 'react-router-dom';
import antControlImage from '../images/ant1.webp';
import rodentControlImage from '../images/rodent1.jpg';
import bedBugControlImage from '../images/justin-lauria-Ap0alm8xpxw-unsplash.jpg';

const services = [
    { title: 'Bed Bug Control', description: 'Get rid of bed bugs quickly.', icon: <PestControlIcon />, backgroundImage: bedBugControlImage, link: '/service_bedbugs' },
    { title: 'Rodent Control', description: 'Keep your home rodent-free.', icon: <PestControlIcon />, backgroundImage: rodentControlImage, link: '/service_rodent' },
    { title: 'Termite Control', description: 'Protect your property from termites.', icon: <PestControlIcon />, backgroundImage: rodentControlImage, link: '/service_termite' },
    { title: 'Fly', description: 'Eliminate Flies from your home.', icon: <PestControlIcon />, backgroundImage: rodentControlImage, link: '/service_fly' },
    { title: 'Ant Control', description: 'Effective solutions for ant infestations.', icon: <PestControlIcon />, backgroundImage: antControlImage, link: '/ant-control' },
    { title: 'Bed Bug Control', description: 'Get rid of bed bugs quickly.', icon: <PestControlIcon />, backgroundImage: bedBugControlImage, link: '/service_bedbugs' },
    { title: 'Rodent Control', description: 'Keep your home rodent-free.', icon: <PestControlIcon />, backgroundImage: rodentControlImage, link: '/service_rodent' },
    { title: 'Termite Control', description: 'Protect your property from termites.', icon: <PestControlIcon />, backgroundImage: rodentControlImage, link: '/service_termite' },
    { title: 'Fly', description: 'Eliminate Flies from your home.', icon: <PestControlIcon />, backgroundImage: rodentControlImage, link: '/service_fly' },
    { title: 'Ant Control', description: 'Effective solutions for ant infestations.', icon: <PestControlIcon />, backgroundImage: antControlImage, link: '/ant-control' },
];

const Allservices: React.FC = () => {
    return (
        <Box sx={{ padding: '2rem', backgroundColor: '#f5f5f5' }}>
            <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: '1.5rem' }}>
                Our Services
            </Typography>
            <Grid container spacing={3}>
                {services.map((service, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Box sx={{
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            background: `url(${service.backgroundImage}) center/cover no-repeat`, 
                            padding: '1rem',
                            borderRadius: '12px',
                            boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
                            textAlign: 'center',
                            transition: 'transform 0.3s, box-shadow 0.3s',
                            margin: '0 auto',
                            overflow: 'hidden', 
                            '&:hover': {
                                transform: 'translateY(-10px)',
                                boxShadow: '0 6px 20px rgba(0, 0, 0, 0.15)',
                            },
                            '&::after': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.4)',
                                zIndex: 1,
                            }
                        }}>
                            <Box sx={{ fontSize: '1rem', color: 'white', marginBottom: '0.5rem', zIndex: 2 }}>
                                {service.icon}
                            </Box>
                            <Typography variant="h6" sx={{ marginBottom: '0.5rem', fontWeight: 'bold', color: 'white', fontSize: '2rem', zIndex: 2 }}>
                                {service.title}
                            </Typography>
                            <Typography sx={{ marginBottom: '1rem', color: 'white', zIndex: 2 }}>
                                {service.description}
                            </Typography>
                            <Button
                                component={Link}
                                to={service.link}
                                variant="contained"
                                color="primary"
                                sx={{
                                    backgroundColor: '#3f51b5',
                                    zIndex: 2,
                                    '&:hover': {
                                        backgroundColor: '#2c387e',
                                    }
                                }}
                            >
                                Learn More
                            </Button>
                        </Box>
                    </Grid>
                ))}
            </Grid>
            
        </Box>
    );
}

export default Allservices;
