import React from 'react';
import { Container, Typography, Grid, Box, Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
import cer1 from "../License and Certificate pdf/cerone.jpg"
import cer2 from "../License and Certificate pdf/certwo.jpg"
import cer3 from "../License and Certificate pdf/certhree.jpg"

const Lcsegment: React.FC = () => {
  return (
    <Container maxWidth="lg" sx={{ padding: '2rem', textAlign: 'center' }}>
      
      <Typography variant="h4" sx={{ marginBottom: '2rem' }}>
      license and certification
      </Typography>

    
      <Grid container spacing={4} justifyContent="center">
       
        <Grid item xs={12} sm={4}>
          <Box
            sx={{
              border: '1px solid #ccc',
              borderRadius: '8px',
              padding: '1rem',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
            }}
          >
            <Box
              component="img"
              src={cer1}
              alt="PDF 1"
              sx={{
                width: '100%',
                height: '200px',
                objectFit: 'cover',
                marginBottom: '1rem',
                borderRadius: '8px',
              }}
            />
            <Typography variant="h6" sx={{ marginBottom: '1rem' }}>
              PDF Document 1
            </Typography>
          </Box>
        </Grid>

        {/* PDF 2 */}
        <Grid item xs={12} sm={4}>
          <Box
            sx={{
              border: '1px solid #ccc',
              borderRadius: '8px',
              padding: '1rem',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
            }}
          >
            <Box
              component="img"
              src={cer2} 
              alt="PDF 2"
              sx={{
                width: '100%',
                height: '200px',
                objectFit: 'cover',
                marginBottom: '1rem',
                borderRadius: '8px',
              }}
            />
            <Typography variant="h6" sx={{ marginBottom: '1rem' }}>
              PDF Document 2
            </Typography>
          </Box>
        </Grid>

        {/* PDF 3 */}
        <Grid item xs={12} sm={4}>
          <Box
            sx={{
              border: '1px solid #ccc',
              borderRadius: '8px',
              padding: '1rem',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
            }}
          >
            <Box
              component="img"
              src={cer3} 
              alt="PDF 3"
              sx={{
                width: '100%',
                height: '200px',
                objectFit: 'cover',
                marginBottom: '1rem',
                borderRadius: '8px',
              }}
            />
            <Typography variant="h6" sx={{ marginBottom: '1rem' }}>
              PDF Document 3
            </Typography>
          </Box>
        </Grid>
      </Grid>

      
      <Box sx={{ marginTop: '2rem' }}>
     
        <NavLink to="/LicenseCertificatePage" style={{ textDecoration: 'none' }}>
          <Button variant="contained" color="primary">
            View More
          </Button>
        </NavLink>
      </Box>
    </Container>
  );
};

export default Lcsegment;
