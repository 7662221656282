import React from 'react';
import { Grid, Box, Typography, useTheme, useMediaQuery } from '@mui/material';

const Pestcover = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box sx={{ marginTop: 6, marginBottom: 6 }}>
            <Grid container spacing={4} justifyContent="center">
                {/* Left side content */}
                <Grid item xs={10} sm={10} md={5} sx={{ textAlign: 'left' }}>
                    <Typography
                        variant="h4"
                        gutterBottom
                        sx={{ color: '#00A79D', fontSize: isMobile ? '1.5rem' : '2rem', textAlign: isMobile ? 'center' : 'left' }}
                    >
                        Pest Covers
                    </Typography>
                    <ul style={{ listStyleType: 'disc', paddingLeft: isMobile ? '20px' : '40px', color: '#333' }}>
                        <li>
                            <Typography
                                variant="body1"
                                gutterBottom
                                sx={{ fontSize: isMobile ? '1rem' : '1.1rem', color: '#333' }}
                            >
                                <strong>House Mouse-</strong>
                            </Typography>
                            <Typography
                                variant="body2"
                                gutterBottom
                                sx={{ fontSize: isMobile ? '0.9rem' : '1rem', color: '#555' }}
                            >
                                They are small in size, very clever, good climbers, and live in homes & social environments.
                            </Typography>
                        </li>
                        <li>
                            <Typography
                                variant="body1"
                                gutterBottom
                                sx={{ fontSize: isMobile ? '1rem' : '1.1rem', color: '#333' }}
                            >
                                <strong>Roof Rats-</strong>
                            </Typography>
                            <Typography
                                variant="body2"
                                gutterBottom
                                sx={{ fontSize: isMobile ? '0.9rem' : '1rem', color: '#555' }}
                            >
                                They are large in size, their tail is longer than their head + body length, sexual maturity is attended in 8 to 12 weeks. Body weighs up to 150-200gms.
                            </Typography>
                        </li>
                        <li>
                            <Typography
                                variant="body1"
                                gutterBottom
                                sx={{ fontSize: isMobile ? '1rem' : '1.1rem', color: '#333' }}
                            >
                                <strong>Norway Rats-</strong>
                            </Typography>
                            <Typography
                                variant="body2"
                                gutterBottom
                                sx={{ fontSize: isMobile ? '0.9rem' : '1rem', color: '#555' }}
                            >
                                Commonly found in drainage. Their tail is shorter than their head + body length. They prefer food containing carbohydrates & proteins. Body weighs up to 400gm.
                            </Typography>
                        </li>
                        <li>
                            <Typography
                                variant="body1"
                                gutterBottom
                                sx={{ fontSize: isMobile ? '1rem' : '1.1rem', color: '#333' }}
                            >
                                <strong>Bandicoot-</strong>
                            </Typography>
                            <Typography
                                variant="body2"
                                gutterBottom
                                sx={{ fontSize: isMobile ? '0.9rem' : '1rem', color: '#555' }}
                            >
                                Very ferocious looking rodent with a long face. It does considerable damage to fields & buildings. Adult weight is between 300 to 1000gm.
                            </Typography>
                        </li>
                    </ul>
                </Grid>

                {/* Right side content */}
                <Grid item xs={10} sm={10} md={5} sx={{ textAlign: 'left' }}>
                    <Typography
                        variant="h4"
                        gutterBottom
                        sx={{ color: '#00A79D', fontSize: isMobile ? '1.5rem' : '2rem', textAlign: isMobile ? 'center' : 'left' }}
                    >
                        Treatment for Rodent Control
                    </Typography>
                    <Typography
                        variant="body1"
                        gutterBottom
                        sx={{ fontSize: isMobile ? '1rem' : '1.1rem', color: '#555' }}
                    >
                        Sound environmental sanitation is the most effective strategy. Rats require three things: food, water & shelter. If these are denied, rats will naturally decrease in density.
                    </Typography>
                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ fontSize: isMobile ? '1.1rem' : '1.2rem', color: '#333' }}
                    >
                        The control measure is divided into two parts:
                    </Typography>
                    <ol style={{ paddingLeft: isMobile ? '20px' : '40px', color: '#333' }}>
                        <li>
                            <Typography
                                variant="body1"
                                gutterBottom
                                sx={{ fontSize: isMobile ? '1rem' : '1.1rem', color: '#333' }}
                            >
                                <strong>Chemical Measure-</strong>
                            </Typography>
                            <Typography
                                variant="body2"
                                gutterBottom
                                sx={{ fontSize: isMobile ? '0.9rem' : '1rem', color: '#555' }}
                            >
                                The anticoagulant rodenticides are used. The poison will be mixed in food material & baiting is done. Baiting will be done only for outside compound premises.
                            </Typography>
                        </li>
                        <li>
                            <Typography
                                variant="body1"
                                gutterBottom
                                sx={{ fontSize: isMobile ? '1rem' : '1.1rem', color: '#333' }}
                            >
                                <strong>Mechanical Measure-</strong>
                            </Typography>
                            <Typography
                                variant="body2"
                                gutterBottom
                                sx={{ fontSize: isMobile ? '0.9rem' : '1rem', color: '#555' }}
                            >
                                Trapping is done by using Scissor Traps, Wonder Traps, Cages & Glue boards.
                            </Typography>
                        </li>
                    </ol>
                    <Typography
                        variant="h5"
                        gutterBottom
                        sx={{ color: '#00A79D', fontSize: isMobile ? '1.3rem' : '1.5rem', textAlign: isMobile ? 'center' : 'left' }}
                    >
                        Trapping:
                    </Typography>
                    <Typography
                        variant="body1"
                        gutterBottom
                        sx={{ fontSize: isMobile ? '1rem' : '1.1rem', color: '#555' }}
                    >
                        The Trapping is done by inspecting the runway of rodents & will be done for inside premises; if necessary it will be done for outside premises also.
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Pestcover;
