import React, { useEffect, useState } from 'react';
import { Container, Box, Typography, Card, CardContent, CardActions, Button, Grid, TextField, Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

interface Blog {
  id: number;
  blogTitle: string;
  blogSubTitle: string;
  blogDescription: string;
  content: any[];
  date: string;
  tags: any;
  blogThumbnail?: string; 
}

const BlogCardPage: React.FC = () => {
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [filteredBlogs, setFilteredBlogs] = useState<Blog[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://cpca-api.midastix.com/blog');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data: Blog[] = await response.json();

        data.sort((a: Blog, b: Blog) => a.id - b.id);

        data.forEach(blog => { 
          if (blog.blogThumbnail) {
            blog.blogThumbnail = `https://cpca-api.midastix.com/${blog.blogThumbnail}`;
          }
        });

        setBlogs(data);
        setFilteredBlogs(data);
      } catch (error) {
        console.error('Error fetching blog data:', error);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredBlogs(
      blogs.filter(
        (blog) =>
          blog.blogTitle.toLowerCase().includes(query) ||
          blog.blogSubTitle.toLowerCase().includes(query) ||
          blog.blogDescription.toLowerCase().includes(query)
      )
    );
  };

  const prominentBlog = blogs.length > 0 ? blogs[0] : null;

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
          Home
        </Link>
        <Link to="/blogs" style={{ textDecoration: 'none', color: 'inherit' }}>
          Blogs
        </Link>
      </Breadcrumbs>

      {prominentBlog && (
        <Box
          sx={{
            pb: 2,
            mb: 4,
            mt: 4,
            textAlign: 'center',
            backgroundImage: `url(${prominentBlog.blogThumbnail})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            height: '300px',
            position: 'relative',
          }}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
              color: 'white',
            }}
          >
            <Grid item xs={12} md={8}>
              <Typography variant="h2" component="h2" sx={{ mt: 1 }}>
                {prominentBlog.blogTitle}
              </Typography>
              <Typography variant="subtitle1" paragraph>
                {prominentBlog.blogSubTitle}
              </Typography>
              <Typography variant="subtitle1" paragraph>
                {prominentBlog.blogDescription}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}

      <Box sx={{ mb: 4 }}>
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearch}
        />
      </Box>

      <Grid container spacing={1}>
        {filteredBlogs.map((blog) => (
          <Grid item xs={12} md={6} key={blog.id}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'row',
                boxShadow: '0 14px 8px rgba(0, 0, 0, 0.3)',
                borderRadius: '20px',
                height: '370px',
              }}
            >
              <Box
                sx={{
                  width: '40%',
                  flexShrink: 0,
                  borderRadius: '20px 0 0 20px',
                }}
              >
                <img
                  src={blog.blogThumbnail}
                  alt={blog.blogTitle}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '20px 0 0 20px',
                  }}
                />
              </Box>
              <Box sx={{ p: 2, width: '60%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {blog.blogTitle}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary" paragraph>
                    {blog.blogSubTitle}
                  </Typography>
                  
                 
                  <Box
                    sx={{
                      height: '140px', 
                      overflowY: 'auto', 
                      paddingRight: '10px',
                    }}
                  >
                    <Typography variant="body2" color="textSecondary">
                      {blog.blogDescription}
                    </Typography>
                  </Box>

                  <Typography variant="subtitle2" color="textSecondary" sx={{ mt: 2 }}>
                    Date: {new Date(blog.date).toLocaleDateString()}
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'flex-end', mt: 'auto' }}>
                  <Button component={Link} to={`/blog/${blog.id}`} size="small" color="primary">
                    Read More
                  </Button>
                </CardActions>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default BlogCardPage;
