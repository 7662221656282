import React from 'react';
import { Grid, Box, Typography, useTheme, useMediaQuery } from '@mui/material';

const Flycontrol = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box sx={{ marginTop: 6, marginBottom: 6 }}>
            <Grid container spacing={3} justifyContent="center">
                <Grid item xs={10} sm={10} md={10}>
                    <Typography
                        variant="h4"
                        gutterBottom
                        sx={{ color: '#00A79D', fontSize: isMobile ? '1.5rem' : '2rem', textAlign: 'center' }}
                    >
                        Types of Flies
                    </Typography>
                </Grid>
                
                {/* First Column */}
                <Grid item xs={10} sm={5} md={5}>
                    <Typography variant="h5" gutterBottom sx={{ color: '#00A79D' }}>
                        House Fly (Musca domestica)
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ color: '#333' }}>
                        The common house fly is a dull gray fly, ¼-inch long with four dark stripes on the middle section (thorax) of its body. House flies typically lay eggs on animal feces and garbage.
                    </Typography>
                    <Typography variant="h5" gutterBottom sx={{ color: '#00A79D' }}>
                        Blow Flies (Calliphoridae)
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ color: '#333' }}>
                        Blow flies develop larvae inside the bodies of dead animals. They also are attracted to garbage. They have been called “bottle flies” because their shiny blue and green color resemble colored glass bottles. Large numbers of these flies indoors usually indicate the presence of a dead animal such as a mouse or bird inside the structure.
                    </Typography>
                    <Typography variant="h5" gutterBottom sx={{ color: '#00A79D' }}>
                        Flesh Flies (Sarcophagidae)
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ color: '#333' }}>
                        Flesh flies usually seek carrion or scraps of meat on which to lay their eggs. Like house flies, adult flesh flies are dark-colored (gray or black). Common species have three dark stripes on the thorax. They are slightly larger than house flies and have a checkerboard pattern on the abdomen.
                    </Typography>
                </Grid>

                {/* Second Column */}
                <Grid item xs={10} sm={5} md={5}>
                    <Typography variant="h5" gutterBottom sx={{ color: '#00A79D' }}>
                        Fruit Flies (Drosophila)
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ color: '#333' }}>
                        Fruit flies are attracted to sweet or fermented liquids such as liquor, syrup, soda pop, and vinegar, in addition to ripening/rotting fruit. Females lay eggs in and around these materials upon which their tiny larvae feed. The gnat-sized adults typically have tan-colored bodies and red eyes.
                    </Typography>
                    <Typography variant="h5" gutterBottom sx={{ color: '#00A79D' }}>
                        Drain Flies (Psychodidae)
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ color: '#333' }}>
                        Drain flies are about 1/8-inch long, adult drain flies are slightly larger than other small flies. Their broad, hairy wings have given rise to another name: moth fly. They also have been called sewer flies, because they infest raw sewage. Drain fly adults are often noticed resting on bathroom walls.
                    </Typography>
                    <Typography variant="h5" gutterBottom sx={{ color: '#00A79D' }}>
                        Control & Prevention Tips
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ color: '#333' }}>
                        1. Always keep your surroundings clean.
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ color: '#333' }}>
                        2. Wipe off food remains spilled on the ground, kitchen slabs, dining tables, etc.
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ color: '#333' }}>
                        3. Dispose of any old garbage, clothes and other waste materials.
                    </Typography>
                </Grid>

                {/* Treatments Segment */}
                <Grid item xs={11} sm={10} md={10}>
                    <Typography
                        variant="h4"
                        gutterBottom
                        sx={{ color: '#00A79D', fontSize: isMobile ? '1.5rem' : '2rem', textAlign: 'center' }}
                    >
                        Treatments
                    </Typography>
                    <Box sx={{ paddingLeft: isMobile ? 2 : 0 }}>
                        <Typography variant="h6" gutterBottom sx={{ color: '#00A79D' }}>
                            Sanitation:
                        </Typography>
                        <Typography variant="body1" gutterBottom sx={{ color: '#333' }}>
                            The key to managing all flies is sanitation. Eliminating fly breeding sites, i.e., the material to which they are attracted to land on which they lay eggs is usually sufficient to eliminate and prevent fly infestations.
                        </Typography>
                        <Typography variant="h6" gutterBottom sx={{ color: '#00A79D' }}>
                            Mechanical Control:
                        </Typography>
                        <Typography variant="body1" gutterBottom sx={{ color: '#333' }}>
                            The key to managing all flies is sanitation. Eliminating fly breeding sites, i.e., the material to which they are attracted to land on which they lay eggs is usually sufficient to eliminate and prevent fly infestations.
                        </Typography>
                        <Typography variant="h6" gutterBottom sx={{ color: '#00A79D' }}>
                            Chemical Control:
                        </Typography>
                        <Typography variant="body1" gutterBottom sx={{ color: '#333' }}>
                            Chemical control can be a valuable component of an integrated fly management program. Residual wall sprays can be applied where the flies congregate. Sprays are done on vertical walls and other breeding sites with wettable powder formulation, and the use of fly baits near adult feeding sources.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Flycontrol;
