import React, { useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import backimage from '../../images/b2.png';
import Footer from '../footer';
import GlobalTeam from '../global';
import GetQuote from '../freequote';
import { Link } from 'react-router-dom';
import BedBugControl from './bugcontrol_segment';


const Bedbugs: React.FC = () => {
    useEffect(() => {

        window.scrollTo(0, 0);
    }, []);
    return (
        <Box>
            
            <Box
                sx={{
                    position: 'relative',
                    backgroundImage: `url(${backimage})`,
                    backgroundSize: 'cover',
                    height: {sm:'60vh' , xs:'70vh'},
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    color: 'white',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                }}
            >
                <Grid container>
                    <Grid 
                      item 
                      sm={10}
                      xs={10} 
                      md={9} 
                      sx={{ 
                       position: 'relative', 
                       zIndex: 1, 
                       margin: '0 auto' 
                    }}>
                        <Typography 
                        variant="h2" 
                        sx={{ 
                            mb: 2,
                            color: 'white', 
                            textAlign: 'center', 
                            fontSize:{xs:'28px' ,sm:'48px'}
                        }}>
                            Bed Bugs Inspection and Control
                        </Typography>

                        <Typography 
                         variant="body1" 
                         sx={{ 
                            color: 'white', 
                            fontSize:{
                                xs:'14px',
                                md:'20px',
                                sm:'24px'
                            }, 
                            textAlign: 'center',
                            mb:3 
                        }}>
                            At CPCS we provide eco-friendly and highly effective post construction termite control services to our clients on their various sites & places. Our offered services include inspection, monitoring and implementation of effective anti-termite treatment as per the condition. We offer budget friendly & guaranteed treatment to make the area completely free from termites.
                        </Typography>
                        
                        <Button
                            component={Link}
                            to="../freequote"
                            variant="contained"
                            sx={{
                                background: 'yellow',
                                color: 'black',
                                mt:'10px',
                                width:{
                                     xs:'150px' ,
                                     md:'150px',
                                     sm:'150px'
                                },
                                minHeight: '60px',
                                margin: 1,
                                padding: '10px',
                                borderRadius: '30px',
                                '&:hover': {
                                    background: 'lightyellow',
                                    fontWeight: 'bold',
                                },
                                '& .MuiTypography-root': {
                                    flexGrow: 1,
                                    textAlign: 'center',
                                },
                                '& .MuiButton-startIcon': {
                                    marginRight: '8px',
                                },
                                '& .MuiButton-endIcon': {
                                    marginLeft: '6px',
                                },
                            }}
                        >
                            <Typography 
                                variant="body1" 
                                color={'black'} 
                                sx={{
                                  fontSize:{
                                     xs:'10px',
                                     sm:'16px' 
                                  },
                            }}>
                              Request an appointment
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>


            </Box>
            
            <BedBugControl/>
            <GlobalTeam />
            <GetQuote />
            <Footer />

        </Box>
    );
};

export default Bedbugs;
