
import React from 'react';
import { Box, Grid, Typography, Card, CardContent, CardMedia } from '@mui/material';
import termiteimage1 from "../../images/termite1.png"
import termiteimage2 from "../../images/termite2.png"
import termiteimage3 from "../../images/termite3.png"
import termiteimage4 from "../../images/termite4.png"


const cardData = [
    {
        id: 1,
        imageUrl: termiteimage1,
        heading: "Swarming termites",
        content: "Swarming termites have light brown to black bodies with a broad waist and straight antennae."
    },
    {
        id: 2,
        imageUrl: termiteimage2,
        heading: "Termites - cellulose",
        content: "Termites feed on the cellulose found in wood, plants, cotton fibers, and paper products"
    },
    {
        id: 3,
        imageUrl: termiteimage3,
        heading: "Termites swarm",
        content: "Termites swarm in the spring or summer in search of somewhere to start a new colony."
    },
    {
        id: 4,
        imageUrl: termiteimage4,
        heading: "Termites - wood",
        content: "Signs of termites include blistering wood flooring, droppings resembling saw dust, mud tubes, swarms, and discarded termite wings."
    }
];

const TermiteSegment: React.FC = () => {
    return (
        <Box sx={{ bgcolor: 'white', py: 4, px: 2 }}>
            <Grid container justifyContent="center" spacing={4}>
                <Grid item xs={12} sx={{ textAlign: 'center', color: '#001457' }}>
                    <Typography variant="h3" sx={{ mb: 2, color: '#001457' }}>
                        Are Termites in Your Home?
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 4 }}>
                        Termites are small insects that live in colonies that can grow into the thousands and even millions. A mature colony can cause structural damage to your home before you start to notice the signs of an infestation.
                    </Typography>
                </Grid>

                
                {cardData.map((card) => (
                    <Grid key={card.id} item xs={12} sm={6} md={3}>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardMedia
                                component="img"
                                height="180"
                                image={card.imageUrl}
                                alt="Image"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div" sx={{ color: '#001457', fontSize: '30px', position: 'relative' }}>
                                    {card.heading}
                                    <div style={{ borderBottom: '2px solid red', width: '100%', position: 'absolute', bottom: '-8px', left: 0 }} />
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{ color: '#001457', fontSize: '15px' }}>
                                    {card.content}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default TermiteSegment;
