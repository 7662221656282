import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ContentDropzone from '../blogs/contentDropzone';

interface Content {
  content: string;
  order: number;
  contentTitle: string;
  file: File | null;
  filePreview: string | null;
}

const CombinedForm: React.FC = () => {
  const [blogTitle, setBlogTitle] = useState('');
  const [blogSubtitle, setBlogSubTitle] = useState('');
  const [blogDescription, setBlogDescription] = useState('');
  const [contents, setContents] = useState<Content[]>([{
    content: '',
    order: 1,
    contentTitle: '',
    file: null,
    filePreview: null,
  }]);
  const [secretKey, setSecretKey] = useState('CCBxZgzRIAwYmhx9');
  const [blogFile, setBlogFile] = useState<File | null>(null);
  const [blogFilePreview, setBlogFilePreview] = useState<string | null>(null);

  const navigate = useNavigate();

  const handleContentChange = (index: number, key: string, value: string | number) => {
    const newContents = [...contents];
    newContents[index] = { ...newContents[index], [key]: value };
    setContents(newContents);
  };

  const addContentField = () => {
    const nextOrder = contents.length + 1;
    setContents([...contents, {
      content: '',
      order: nextOrder,
      contentTitle: '',
      file: null,
      filePreview: null,
    }]);
  };

  const removeContentField = (index: number) => {
    const newContents = contents.filter((_, i) => i !== index);
    setContents(newContents);
  };

  const onDropBlogFile = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      setBlogFile(file);
      setBlogFilePreview(URL.createObjectURL(file));
    }
  }, []);

  const onDropContentFile = useCallback((index: number, acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const newContents = [...contents];
    newContents[index] = { ...newContents[index], file, filePreview: URL.createObjectURL(file) };
    setContents(newContents);
  }, [contents]);

  const removeBlogFile = () => {
    setBlogFile(null);
    setBlogFilePreview(null);
  };

  const removeContentFile = (index: number) => {
    const newContents = [...contents];
    newContents[index] = { ...newContents[index], file: null, filePreview: null };
    setContents(newContents);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const blogFormData = new FormData();
      blogFormData.append('blogTitle', blogTitle);
      blogFormData.append('blogSubtitle', blogSubtitle);
      blogFormData.append('blogDescription', blogDescription);
      blogFormData.append('secretKey', secretKey);
      if (blogFile) {
        blogFormData.append('file', blogFile);
      }

      const blogResponse = await fetch('https://cpca-api.midastix.com/blog', {
        method: 'POST',
        body: blogFormData,
      });

      if (!blogResponse.ok) {
        const errorText = await blogResponse.text();
        console.error(`Failed to post blog: ${blogResponse.statusText} - ${errorText}`);
        throw new Error(`Failed to post blog: ${blogResponse.statusText}`);
      }

      const blogData = await blogResponse.json();
      const generatedBlogId = blogData.id;

      const contentIds: number[] = [];

      await Promise.all(contents.map(async (contentObj) => {
        const contentFormData = new FormData();
        if (contentObj.file) {
          contentFormData.append('file', contentObj.file);
        }
        contentFormData.append('content', contentObj.content);
        contentFormData.append('order', contentObj.order.toString());
        contentFormData.append('contentTitle', contentObj.contentTitle);

        const contentUrl = `https://cpca-api.midastix.com/blog/${generatedBlogId}/content`;

        const contentResponse = await fetch(contentUrl, {
          method: 'POST',
          body: contentFormData,
        });

        if (!contentResponse.ok) {
          const errorText = await contentResponse.text();
          console.error(`Failed to post content: ${contentResponse.statusText} - ${errorText}`);
          throw new Error(`Failed to post content: ${contentResponse.statusText}`);
        }

        const contentData = await contentResponse.json();
        contentIds.push(contentData.id);

        return contentData;
      }));

      // Store blogId and contentIds to navigate to SubContentForm
      navigate('/subcontentform', { state: { blogId: generatedBlogId, contentIds } });
    } catch (error) {
      console.error('Error posting blog or content:', error);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleForward = () => {
    navigate(1);
  };

  return (
    <Container maxWidth="sm">
      <Box display="flex" justifyContent="space-between" mt={2}>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        <IconButton onClick={handleForward}>
          <ArrowForwardIcon />
        </IconButton>
      </Box>
      <Typography variant="h4" component="h1" gutterBottom>
        Create a New Blog and Content Post
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Blog Title"
          fullWidth
          margin="normal"
          value={blogTitle}
          onChange={(e) => setBlogTitle(e.target.value)}
          required
        />
        <TextField
          label="Blog Subtitle"
          fullWidth
          margin="normal"
          value={blogSubtitle}
          onChange={(e) => setBlogSubTitle(e.target.value)}
          required
        />
        <TextField
          label="Blog Description"
          fullWidth
          margin="normal"
          multiline
          rows={4}
          value={blogDescription}
          onChange={(e) => setBlogDescription(e.target.value)}
          required
        />

        <Typography variant="h6" gutterBottom>
          Blog File
        </Typography>
        <ContentDropzone onDrop={onDropBlogFile} />
        {blogFilePreview && (
          <Box mt={2}>
            <Typography variant="body1">File Preview:</Typography>
            <img src={blogFilePreview} alt="Blog file preview" width={200} />
            <Button onClick={removeBlogFile} color="secondary">
              Remove File
            </Button>
          </Box>
        )}

        {contents.map((content, index) => (
          <Box key={index} mt={2} border={1} borderColor="grey.400" p={2}>
            <Typography variant="h6">Content #{index + 1}</Typography>
            <TextField
              label="Content Title"
              fullWidth
              margin="normal"
              value={content.contentTitle}
              onChange={(e) => handleContentChange(index, 'contentTitle', e.target.value)}
            />
            <TextField
              label="Content"
              fullWidth
              margin="normal"
              multiline
              rows={4}
              value={content.content}
              onChange={(e) => handleContentChange(index, 'content', e.target.value)}
            />
            <ContentDropzone onDrop={(acceptedFiles) => onDropContentFile(index, acceptedFiles)} />
            {content.filePreview && (
              <Box mt={2}>
                <Typography variant="body1">File Preview:</Typography>
                <img src={content.filePreview} alt="Content file preview" width={200} />
                <Button onClick={() => removeContentFile(index)} color="secondary">
                  Remove File
                </Button>
              </Box>
            )}

            <IconButton onClick={() => removeContentField(index)} color="secondary">
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
        <Button onClick={addContentField} variant="contained" color="primary" startIcon={<AddIcon />} >
          Add Content
        </Button>
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </form>
    </Container>
  );
};

export default CombinedForm;
