// components/GetQuote.tsx
import React, { useState } from 'react';
import { Box, Grid, TextField, Button, Typography, Container, Paper } from '@mui/material';
import bugimage from "../images/justin-lauria-Ap0alm8xpxw-unsplash.jpg";

const GetQuote: React.FC = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // Handle form submission (e.g., send data to server)
        console.log(formData);
    };

    return (
        <Box sx={{ 
            backgroundImage: `url(${bugimage})`, 
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            minHeight: '100vh', // Adjust as needed for your design
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '2rem 0',
        }}>
            <Container maxWidth="md">
                <Paper elevation={3} sx={{ padding: '2rem', borderRadius: '12px', backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: '1.5rem' }}>
                        Get a Quote
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    name="name"
                                    variant="outlined"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                    sx={{ marginBottom: '1rem' }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    variant="outlined"
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                    sx={{ marginBottom: '1rem' }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Phone"
                                    name="phone"
                                    variant="outlined"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    required
                                    sx={{ marginBottom: '1rem' }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Message"
                                    name="message"
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                    sx={{ marginBottom: '1rem' }}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                <Button type="submit" variant="contained" color="primary" sx={{ padding: '0.75rem 2rem' }}>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>
        </Box>
    );
};

export default GetQuote;
