import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

interface Content {
  id: number;
  contentTitle: string;
  content: string;
  order: string;
  contentImg?: string | null;
}

const UpdateContentPage: React.FC = () => {
  const location = useLocation();
  const { blogId } = location.state as { blogId: string };
  const navigate = useNavigate();
  const [contentList, setContentList] = useState<Content[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState<{ [key: number]: File | null }>({});
  const [contentImgPreview, setContentImgPreview] = useState<{ [key: number]: string | null }>({});

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await fetch(`https://cpca-api.midastix.com/blog/${blogId}/content`);
        if (!response.ok) {
          throw new Error('Failed to fetch content');
        }
        const data = await response.json();
        setContentList(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching content:', error);
        setLoading(false);
      }
    };

    fetchContent();
  }, [blogId]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, contentId: number) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setSelectedFile((prevState) => ({
        ...prevState,
        [contentId]: file,
      }));
      setContentImgPreview((prevState) => ({
        ...prevState,
        [contentId]: URL.createObjectURL(file),
      }));
    }
  };

  const handleUpdateContent = async (contentId: number) => {
    const contentToUpdate = contentList.find((content) => content.id === contentId);
    if (!contentToUpdate) {
      alert('Content data is not available.');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('contentTitle', contentToUpdate.contentTitle);
      formData.append('content', contentToUpdate.content);
      formData.append('order', contentToUpdate.order);

      if (selectedFile[contentId]) {
        formData.append('contentImg', selectedFile[contentId] as File);
      }

      const response = await fetch(`https://cpca-api.midastix.com/blog/${blogId}/content/${contentId}`, {
        method: 'PUT',
        body: formData,
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`Server Error: ${errorText}`);
        throw new Error(`Failed to update content: ${errorText}`);
      }

      alert('Content updated successfully');
      navigate(`/blog/${blogId}`);
    } catch (error) {
      console.error('Error updating content:', error);
      alert('Failed to update content. Please try again later.');
    }
  };

  const handleNavigateSubcontent = (contentId: number, contentTitle: string) => {
    navigate(`/updatesubcontent/64`, { state: { blogId, contentId, contentTitle } });
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (!contentList.length) {
    return <Typography>No content available for this blog.</Typography>;
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Update Blog Content
      </Typography>
      {contentList.map((contentData) => (
        <Box key={contentData.id} component="form" noValidate autoComplete="off" mb={4}>
          <TextField
            label="Content Title"
            value={contentData.contentTitle}
            onChange={(e) => {
              const updatedContent = contentList.map((item) =>
                item.id === contentData.id ? { ...item, contentTitle: e.target.value } : item
              );
              setContentList(updatedContent);
            }}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Content"
            value={contentData.content}
            onChange={(e) => {
              const updatedContent = contentList.map((item) =>
                item.id === contentData.id ? { ...item, content: e.target.value } : item
              );
              setContentList(updatedContent);
            }}
            fullWidth
            margin="normal"
            multiline
          />
          <TextField
            label="Order"
            value={contentData.order}
            onChange={(e) => {
              const updatedContent = contentList.map((item) =>
                item.id === contentData.id ? { ...item, order: e.target.value } : item
              );
              setContentList(updatedContent);
            }}
            fullWidth
            margin="normal"
          />
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id={`file-input-${contentData.id}`}
            type="file"
            onChange={(e) => handleFileChange(e, contentData.id)}
          />
          <label htmlFor={`file-input-${contentData.id}`}>
            <Button variant="contained" color="primary" component="span">
              Upload Content Image (Optional)
            </Button>
          </label>
          {contentImgPreview[contentData.id] && (
            <Box mt={2}>
              <Typography variant="subtitle1">Image Preview:</Typography>
              <img
                src={contentImgPreview[contentData.id] as string}
                alt="Content Preview"
                style={{ maxWidth: '100%', height: 'auto' }}
              />
            </Box>
          )}
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleUpdateContent(contentData.id)}
            >
              Update Content
            </Button>
          </Box>
          <Box mt={2}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => handleNavigateSubcontent(contentData.id, contentData.contentTitle)}
            >
              Manage Subcontent
            </Button>
          </Box>
        </Box>
      ))}
    </Container>
  );
};

export default UpdateContentPage;
