import React from 'react';


// import ResponsiveAppBar from './components/header';


import AppRoutes from './routes';
import { Box } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/header';
import { AuthProvider } from './AuthContext';


function App() {
  return (
    <AuthProvider>
    <Router>
    <Header/>
      <AppRoutes />
    </Router>
  </AuthProvider>
    
   
     
   
  );
}

export default App;
