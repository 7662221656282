import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Box, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const BlogListPage: React.FC = () => {
  const [blogPosts, setBlogPosts] = useState<any[]>([]);
  const navigate = useNavigate();

  
  const fetchBlogPosts = async () => {
    try {
      const response = await fetch('https://cpca-api.midastix.com/blog?secret_key=CCBxZgzRIAwYmhx9');
      if (!response.ok) {
        throw new Error('Failed to fetch blog posts');
      }
      const data = await response.json();
      setBlogPosts(data);
    } catch (error) {
      console.error('Error fetching blog posts:', error);
    }
  };

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  
  const handleDeleteBlog = async (blogId: number) => {
    const userSecretKey = prompt('Please enter the secret key to delete the blog:');
    if (!userSecretKey) {
      alert('Secret key is required to delete the blog.');
      return;
    }

    try {
      const blogPost = blogPosts[blogId];

      
      const blogDeleteResponse = await fetch(`https://cpca-api.midastix.com/blog/${blogPost.id}?secretKey=${userSecretKey}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!blogDeleteResponse.ok) {
        const errorText = await blogDeleteResponse.text();
        throw new Error(`Failed to delete blog with ID ${blogPost.id}: ${errorText}`);
      }

      
      setBlogPosts((prevPosts) => prevPosts.filter((post, index) => index !== blogId));
      console.log('Blog deleted successfully');
    } catch (error) {
      console.error('Error deleting blog:', error);
      
    }
  };

  
  const handleNavigateToUpdateBlog = (blogId: number) => {
    navigate(`/updateblog/${blogId}`);
  };
  const handleBack = () => {
    navigate(-1); 
  };

  const handleForward = () => {
    navigate(1); 
  };

  return (
    <Container maxWidth="md">
       <Box display="flex" justifyContent="space-between" mt={2}>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        <IconButton onClick={handleForward}>
          <ArrowForwardIcon />
        </IconButton>
      </Box>
      <Typography variant="h4" gutterBottom>
        Blog Posts
      </Typography>
      {blogPosts.length === 0 ? (
        <Typography variant="body1">No blog posts found.</Typography>
      ) : (
        blogPosts.map((blog, index) => (
          <Box key={index} mb={2} p={2} border="1px solid #ccc">
            <Typography variant="h6">{blog.blogTitle}</Typography>
            <Typography variant="subtitle1">{blog.blogSubTitle}</Typography>
            <Typography variant="body1">{blog.blogDescription}</Typography>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Button variant="contained" color="secondary" onClick={() => handleDeleteBlog(index)}>
                Delete Blog
              </Button>
              <Button variant="contained" color="primary" onClick={() => handleNavigateToUpdateBlog(blog.id)}>
                Update Blog
              </Button>
            </Box>
            
          </Box>
        ))
      )}
      <Button
        component={Link}
        to="/addblogs"
        size="small"
        sx={{
          backgroundColor: 'green',
          mb:4,
          color: 'white',
          '&:hover': {
            backgroundColor: 'darkgreen', 
          },
        }}
      >
           Add Blog
        </Button>
    </Container>
  );
};

export default BlogListPage;
