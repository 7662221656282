// src/components/GlobalTeam.tsx

import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import HandshakeIcon from '@mui/icons-material/Handshake';
import PublicIcon from '@mui/icons-material/Public';
import HomeIcon from '@mui/icons-material/Home';

const GlobalTeam: React.FC = () => {
  return (
    <Box sx={{ textAlign: 'center', padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Global team, neighborhood feel
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={4}>
          <Box>
            <HandshakeIcon sx={{ fontSize: 50 }} />
            <Typography variant="h5">95 Years</Typography>
            <Typography>of pest management experience</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box>
            <PublicIcon sx={{ fontSize: 50 }} />
            <Typography variant="h5">2.9 Million</Typography>
            <Typography>happy customers serviced globally</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box>
            <HomeIcon sx={{ fontSize: 50 }} />
            <Typography variant="h5">50k Homes</Typography>
            <Typography>and businesses serviced daily across the world</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GlobalTeam;
