import React from 'react';
import { Container, Typography, Box, Button, Grid } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import { useState } from 'react';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfAndImagesDisplayPage: React.FC = () => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () => setPageNumber(pageNumber - 1);
  const goToNextPage = () => setPageNumber(pageNumber + 1);

  return (
    <Container maxWidth="md" sx={{ padding: '2rem', textAlign: 'center' }}>
     
      <Typography variant="h4" sx={{ marginBottom: '1rem' }}>
        License Document
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '2rem', fontSize: '1.1rem' }}>
        Below is the license PDF. You can navigate between the pages.
      </Typography>

      {/* PDF Viewer */}
      <Box
        sx={{
          border: '1px solid #ccc',
          padding: '1rem',
          marginBottom: '2rem',
          display: 'inline-block',
          textAlign: 'center',
        }}
      >
        <Document
          file="../"
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <Typography sx={{ marginTop: '1rem' }}>
          Page {pageNumber} of {numPages}
        </Typography>
        <Box sx={{ marginTop: '1rem' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={goToPrevPage}
            disabled={pageNumber <= 1}
            sx={{ marginRight: '1rem' }}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={goToNextPage}
            disabled={pageNumber >= (numPages || 1)}
          >
            Next
          </Button>
        </Box>
      </Box>

     
      <Typography variant="h4" sx={{ marginBottom: '1rem' }}>
        Certificate Images
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '2rem', fontSize: '1.1rem' }}>
        Below are the images of the certificates.
      </Typography>

      <Grid container spacing={2}>
       
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ marginBottom: '1rem' }}>
            Certificate 1
          </Typography>
          <Box
            component="img"
            sx={{
              width: '100%',
              border: '1px solid #ccc',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
            src="/path/to/certificate1.jpg" 
            alt="Certificate 1"
          />
        </Grid>

        {/* Image 2 */}
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ marginBottom: '1rem' }}>
            Certificate 2
          </Typography>
          <Box
            component="img"
            sx={{
              width: '100%',
              border: '1px solid #ccc',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
            src="/path/to/certificate2.jpg" 
            alt="Certificate 2"
          />
        </Grid>

        {/* Image 3 */}
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ marginBottom: '1rem' }}>
            Certificate 3
          </Typography>
          <Box
            component="img"
            sx={{
              width: '100%',
              border: '1px solid #ccc',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
            src="/path/to/certificate3.jpg" 
            alt="Certificate 3"
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default PdfAndImagesDisplayPage;
