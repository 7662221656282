import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import bedbug_image from '../../images/termite2.png'

const Flysrevice = () => {


    return (
        <Grid 
           container 
           spacing={2} 
           sx={{ 
            marginTop: 12, 
            backgroundColor: '#f5f5f5', 
            alignItems: 'center', 
            borderBottom: '2px dotted black' 
            }}>

             <Grid 
                item 
                md={11} 
                xs={11} 
                sx={{ 
                    textAlign: 'left', 
                    margin: 'auto', 
                    position: 'relative',
                    paddingBottom: '12px' 
                }}>
                <Typography 
                    variant='h4' 
                    sx={{ 
                        marginBottom: '12px', 
                        paddingBottom: '4px', 
                        position: 'relative', 
                        zIndex: 1, 
                        fontSize: { xs: '20px' , sm:'28px' } 
                    }}>
                    FLY CONTROL SERVICES
                </Typography>
                <Box sx={{
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    height: '2px',
                    width: '100%',
                    background: 'linear-gradient(to right, red 3cm, black 3cm)'
                }} />
            </Grid>
            <Grid item xs={12} md={5}>
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <img src={bedbug_image} alt="Sample" style={{ maxWidth: '80%', height: '200px', borderRadius: '6px' }} />
                </Box>
            </Grid>
            <Grid item md={5} xs={10} sx={{ margin: '0 auto' }}>
                <Typography sx={{ fontSize: { xs: '10px', md: '16px' ,sm:'24px' }, paddingBottom: 5 }}>
                    There are over 120,000 species of flies worldwide. Flies are considered pests because they pose a health risk to humans, pets and livestock. They can infest your home or business and spread diseases like Salmonella and E. coli. A few species may even bite humans and animals.
                        <br />
                    If a small fly problem is left uncontrolled, it has the potential to turn into a serious infestation. Some fly species are able to mature from eggs to adults in just seven days. There are simple ways you can identify the signs of a fly infestation and reduce the need for fly control. Taking a proactive approach with deterrent measures will also help you avoid costly treatments.
                
                    
                </Typography>
            </Grid>
        </Grid>
    );
}

export default Flysrevice;
