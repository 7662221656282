import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom';
import logo_image from '../images/inverted_image.png';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const pages = ['Home', 'Blogs', 'About', 'Contact Us'];
const aboutPages = ['About CEO', 'About Us', 'Team'];

function Header() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuMouseOver = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuMouseLeave = () => {
    setAnchorEl(null);
  };

  const drawer = (
    <Box sx={{ textAlign: 'center', background: '#001012', height: '100%' }}>
       <NavLink to="/" style={{ textDecoration: 'none' }}>
      <Avatar
        alt="Logo"
        src={logo_image}
        variant="square"
        sx={{
          height: '120px',
          width: 'auto',
          margin: '20px auto',
        }}
      />
      </NavLink>
      <List>
        {pages.map((page) => (
          <NavLink
            key={page}
            to={`/${page.toLowerCase().replace(/ /g, '-')}`}
            style={{ textDecoration: 'none', color: 'white' }}
            onClick={handleDrawerToggle}
          >
            {/* <ListItem button>
              <ListItemText primary={page} />
            </ListItem> */}
          </NavLink>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar position="sticky" sx={{ background: '#001012', color: 'white' }}>
      <Toolbar>
      <NavLink to="/" style={{ textDecoration: 'none' }}>
          <Avatar
            alt="Logo"
            src={logo_image}
            variant="square"
            sx={{
              height: '60px',
              width: 'auto',
              marginRight: '20px',
            }}
          />
        </NavLink>

        <Box sx={{ flexGrow: 1 }} />

        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          {pages.map((page) => (
            <NavLink
              key={page}
              to={`/${page.toLowerCase().replace(/ /g, '-')}`}
              style={{ textDecoration: 'none' }}
            >
              {page === 'About'? (
                <Button
                  sx={{ mx: 1, color: 'white' }}
                  onMouseOver={handleMenuMouseOver}
                  onMouseLeave={handleMenuMouseLeave}
                >
                  {page}
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    {aboutPages.map((aboutPage) => (
                      <NavLink
                        key={aboutPage}
                        to={`/about/${aboutPage.toLowerCase().replace(/ /g, '-')}`}
                        style={{ textDecoration: 'none' }}
                      >
                        <MenuItem onClick={() => setAnchorEl(null)}>
                          {aboutPage}
                        </MenuItem>
                      </NavLink>
                    ))}
                  </Menu>
                </Button>
              ) : (
                <Button sx={{ mx: 1, color: 'white' }}>{page}</Button>
              )}
            </NavLink>
          ))}
        </Box>

        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerToggle}
          sx={{ display: { xs: 'block', md: 'none' }, color: 'white' }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>

      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{ '&.MuiDrawer-paper': { boxSizing: 'border-box', width: 250 } }}
      >
        {drawer}
      </Drawer>
    </AppBar>
  );
}

export default Header;