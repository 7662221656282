// components/HomeSegment.tsx
import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import SampleImage from '../images/justin-lauria-Ap0alm8xpxw-unsplash.jpg';
import b from '../images/b111.png'

const Usp: React.FC = () => {
    return (
        <Box sx={{ padding: '4rem 0', backgroundColor: '#f5f5f5' }}>
            <Container>
                <Grid container spacing={3} alignItems="center" justifyContent="center">
                    <Grid item md={10} xs={10}>
                        <Typography variant="h4" align="center" sx={{ marginBottom: '2rem' ,color:'#001457'}}>
                            Our Process
                        </Typography>
                        <Typography variant="h5" align="center" sx={{ marginBottom: '2rem' ,color:'#001457', fontSize:{xs:'20px'}}}>
                            Professional pest control technicians that know your neighborhood with a process you can trust
                            
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Box display="flex" justifyContent="center">
                            <img src={b} alt="Sample" style={{ maxWidth: '80%', height: '200px', borderRadius: '6px' }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ paddingLeft: '2rem', paddingRight: '2rem' }}>
                        <Typography variant="h4" sx={{ marginBottom: '1rem' ,color:'#001457',textAlign:{xs:'center' , md:'left'}}}>
                        Inspection and Assessment
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: '1rem',color:'#001457',textAlign:{xs:'center', md:'left'} }}>
                        At CPSC, our process begins with a thorough inspection of your property. Our experienced technicians carefully assess the extent of pest infestation and identify potential entry points and nesting sites.
                        </Typography>
                        
                    </Grid>
                </Grid>
                <Grid container spacing={3} alignItems="center" justifyContent="center" sx={{ marginTop: '4rem' }}>
                    <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }} sx={{ paddingLeft: '2rem', paddingRight: '2rem' }}>
                    <Typography variant="h4" sx={{ marginBottom: '1rem' ,color:'#001457',
                        textAlign:{xs:'center', md:'left'}}}>
                        Customized Treatment Plan
                        </Typography>
                        <Typography variant="body1" sx={{textAlign:{xs:'center',md:'left'},color:'black'}} >
                        Based on our assessment, we develop a customized treatment plan tailored to your specific needs. Whether it's dealing with rodents, insects, or other pests, we use eco-friendly solutions to ensure the safety of your family and pets.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={5} order={{ xs: 1, md: 2 }}>
                        <Box display="flex" justifyContent="center">
                            <img src={SampleImage} alt="Sample" style={{ maxWidth: '80%', height: 'auto', borderRadius: '8px' }} />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={3} alignItems="center" justifyContent="center" sx={{ marginTop: '4rem' }}>
                    <Grid item xs={12} md={5}>
                        <Box display="flex" justifyContent="center">
                            <img src={SampleImage} alt="Sample" style={{ maxWidth: '80%', height: 'auto', borderRadius: '8px' }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ paddingLeft: '2rem', paddingRight: '2rem' }}>
                        <Typography variant="h4" sx={{ marginBottom: '1rem',color:'#001457' ,textAlign:{xs:'center' , md:'left'}}}>
                        Monitoring and Prevention
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: '1rem',color:'#001457',textAlign:{xs:'center', md:'left'} }}>
                            After treatment, we monitor the results to ensure pests are eliminated. We provide recommendations on preventing future infestations, including sealing entry points and regular inspections.
                        </Typography>
                       
                    </Grid>
                </Grid>
                <Grid container spacing={3} alignItems="center" justifyContent="center" sx={{ marginTop: '4rem' }}>
                    <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }} sx={{ paddingLeft: '2rem', paddingRight: '2rem' }}>
                        <Typography variant="h4" sx={{ marginBottom: '1rem',color:'#001457',textAlign:{xs:'center' , md:'left'} }}>
                            Customer Satisfaction
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: '1rem',color:'#001457',textAlign:{xs:'center' , md:'left'}}}>
                        Our commitment doesn't end with treatment. We prioritize your satisfaction, providing ongoing support and follow-up services to guarantee a pest-free environment.
                        </Typography>
                        
                    </Grid>
                    <Grid item xs={12} md={5} order={{ xs: 1, md: 2 }}>
                        <Box display="flex" justifyContent="center">
                            <img src={b} alt="Sample" style={{ maxWidth: '80%', height: 'auto', borderRadius: '8px' }} />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Usp;
