// Routes.tsx
import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './components/home';
import Termite from './components/termite/service_termite';
import Bedbugs from './components/bedbugs/service_bedbugs';
import GetQuote from './components/freequote';
import Rodent from './components/rodent/service_rodent';
import OurServices from './components/ourservises';
import Fly from './components/fly/service_fly';
// import Contact from './components/contact-us';
import Allservices from './components/allservices';
import Login from './components/login';
import BlogCardPage from './components/blogs/blogcards';

import CombinedForm from './components/blogs/addblogs';
import PortfolioPage from './components/profile';
import BlogPage from './components/blogs/blog';
import BlogListPage from './components/blogs/bloglist';
import UpdateBlogPage from './components/blogs/updateblog';

import ProtectedRoute from './ProtectedRoute';
import CompanyPage from './components/aboutus';
import TeamPage from './components/team';
import AdminPage from './components/blogs/commentlist';
import SubContentForm from './components/blogs/subcontentform';
import ContactUs from './components/contact-us';
import UpdatecontentPage from './components/blogs/updatecontent';
import LicenseCertificatePage from './components/LicenseCertificatePage';
import UpdateSubContentPage from './components/blogs/updatesubcontent';
import TraininggalleryMain from './components/trainingmain';
import TrainingGalleryPage from './components/traininggallerypage';
import UpdateSubContentForm from './components/blogs/updatesubcontent';



const AppRoutes: React.FC = () => {
  useEffect(() => {
    
    const handleUnload = () => {
      localStorage.removeItem('loginKey');
    };

    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  return (
    <Routes>
      <Route path="/home" element={<Home />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/" element={<Home />} />
      <Route path="/service_termite" element={<Termite />} />
      <Route path="/service_bedbugs" element={<Bedbugs />} />
      <Route path="/freequote" element={<GetQuote />} />
      <Route path="/service_rodent" element={<Rodent />} />
      <Route path="/service_fly" element={<Fly />} />
      <Route path="/ourservices" element={<OurServices />} />
      <Route path="/allservices" element={<Allservices />} />
      <Route path="/login" element={<Login />} />
      <Route path="/updateblog/:id" element={<UpdateBlogPage />} />
      <Route path="/updatecontent/:id" element={<UpdatecontentPage />} />
      <Route path="/addblogs" element={<ProtectedRoute element={<CombinedForm />} />} />
      <Route path="/blogs" element={<BlogCardPage />} />
      <Route path="/about/about-ceo" element={<PortfolioPage />} />
      <Route path="/about" element={<CompanyPage />} />
      <Route path="/about/about-us" element={<CompanyPage />} />
      <Route path="/about/team" element={<TeamPage />} />
      <Route path="/blog/:id" element={<BlogPage />} />
      <Route path="/bloglist" element={<ProtectedRoute element={<BlogListPage />} />} />
      <Route path="/commentlist" element={< AdminPage/>} />
      <Route path="/LicenseCertificatePage" element={< LicenseCertificatePage/>} />
      <Route path="/traininggallery" element={< TrainingGalleryPage/>} />
      <Route path="/subcontentform" element={<SubContentForm />} />
      <Route path="/updatesubcontent/:id" element={<UpdateSubContentPage />} />
     
      
    </Routes>
  );
};

export default AppRoutes;
