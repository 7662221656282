import React from 'react';
import { Container, Typography, Grid, Box } from '@mui/material';
import img1 from "../trainninggallery/tg1.jpg";
import img2 from "../trainninggallery/tg2.jpg";
import img3 from "../trainninggallery/tg3.jpg";
import img4 from "../trainninggallery/tg4.jpg";
import img5 from "../trainninggallery/tg5.jpg";
import img6 from "../trainninggallery/tg6.jpg";
import img7 from "../trainninggallery/tg7.jpg";
import img8 from "../trainninggallery/tg8.jpg";
import img9 from "../trainninggallery/tg9.jpg";
import img10 from "../trainninggallery/tg10.jpg";
import img11 from "../trainninggallery/tg11.jpg";
import img12 from "../trainninggallery/tg12.jpg";
import img13 from "../trainninggallery/tg13.jpg";
import img14 from "../trainninggallery/tg14.jpg";
import img15 from "../trainninggallery/tg15.jpg";

const TrainingGalleryPage: React.FC = () => {
  return (
    <Box sx={{ backgroundColor: '#f5f5f5', padding: '2rem 0' }}>
      <Container maxWidth="lg">
        
        <Typography variant="h3" sx={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '2rem' }}>
          Training Gallery
        </Typography>

       
        <Typography
          variant="h5"
          sx={{
            fontStyle: 'italic',
            textAlign: 'center',
            marginBottom: '3rem',
            color: '#555',
          }}
        >
          "Teamwork makes the dream work, but a vision becomes a nightmare when the leader has a big dream and a bad team."
        </Typography>

       
        <Grid container spacing={4} justifyContent="center">
       
          <Grid item xs={12} sm={4}>
            <Box sx={{ textAlign: 'center' }}>
              <Box
                component="img"
                src={img1}
                alt="Training 1"
                sx={{
                  width: '100%',
                  height: '200px',
                  objectFit: 'cover',
                  borderRadius: '50%',
                  border: '5px solid #ccc',
                }}
              />
              <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                Leadership Workshop
              </Typography>
            </Box>
          </Grid>

          
          <Grid item xs={12} sm={4}>
            <Box sx={{ textAlign: 'center' }}>
              <Box
                component="img"
                src={img2}
                alt="Training 2"
                sx={{
                  width: '100%',
                  height: '200px',
                  objectFit: 'cover',
                  borderRadius: '16px',
                  border: '5px solid #ccc',
                }}
              />
              <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                Team Building Activities
              </Typography>
            </Box>
          </Grid>

         
          <Grid item xs={12} sm={4}>
            <Box sx={{ textAlign: 'center' }}>
              <Box
                component="img"
                src={img3}
                alt="Training 3"
                sx={{
                  width: '100%',
                  height: '200px',
                  objectFit: 'cover',
                  clipPath: 'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)',
                  border: '5px solid #ccc',
                }}
              />
              <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                Problem-Solving Sessions
              </Typography>
            </Box>
          </Grid>

         
          <Grid item xs={12} sm={4}>
            <Box sx={{ textAlign: 'center' }}>
              <Box
                component="img"
                src={img4}
                alt="Training 4"
                sx={{
                  width: '100%',
                  height: '200px',
                  objectFit: 'cover',
                  borderRadius: '25% 75% 25% 75%',
                  border: '5px solid #ccc',
                }}
              />
              <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                Hands-on Training
              </Typography>
            </Box>
          </Grid>

         
          <Grid item xs={12} sm={4}>
            <Box sx={{ textAlign: 'center' }}>
              <Box
                component="img"
                src={img5}
                alt="Training 5"
                sx={{
                  width: '100%',
                  height: '200px',
                  objectFit: 'cover',
                  borderRadius: '20px',
                  border: '5px solid #ccc',
                }}
              />
              <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                Skill Development Workshop
              </Typography>
            </Box>
          </Grid>

          
          {[img6, img7, img8, img9, img10, img11, img12, img13, img14, img15].map((image, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <Box sx={{ textAlign: 'center' }}>
                <Box
                  component="img"
                  src={image}
                  alt={`Training ${index + 6}`}
                  sx={{
                    width: '100%',
                    height: '200px',
                    objectFit: 'cover',
                    borderRadius: index % 2 === 0 ? '50%' : '16px', 
                    border: '5px solid #ccc',
                  }}
                />
                <Typography variant="h6" sx={{ marginTop: '1rem' }}>
                  Training Session {index + 6}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default TrainingGalleryPage;
