import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

interface SubContent {
  id: number;
  subContentTitle: string;
  subContentDescription: string;
}

const UpdateSubContentPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { blogId, contentId, subContentId } = location.state as { blogId: string; contentId: string; subContentId: string };

  const [subContentData, setSubContentData] = useState<SubContent | null>(null);
  const [subContentTitle, setSubContentTitle] = useState('');
  const [subContentDescription, setSubContentDescription] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSubContent = async () => {
      try {
        const response = await fetch(`https://cpca-api.midastix.com/blog/${blogId}/content/${contentId}/subContent/64
          `);
        if (!response.ok) {
          throw new Error('Failed to fetch subcontent');
        }
        const data = await response.json();
        setSubContentData(data);
        setSubContentTitle(data.subContentTitle);
        setSubContentDescription(data.subContentDescription);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching subcontent:', error);
        setLoading(false);
      }
    };

    fetchSubContent();
  }, [blogId, contentId, subContentId]);

  const handleUpdateSubContent = async () => {
    if (!subContentData) {
      alert('Subcontent data is not available.');
      return;
    }

    try {
      const updatedSubContent = {
        subContentTitle,
        subContentDescription,
      };

      const response = await fetch(`https://cpca-api.midastix.com/blog/${blogId}/content/${contentId}/subContent/${subContentId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedSubContent),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`Server Error: ${errorText}`);
        throw new Error(`Failed to update subcontent: ${errorText}`);
      }

      alert('Subcontent updated successfully');
      navigate(`/content/${contentId}/subcontent`); 
    } catch (error) {
      console.error('Error updating subcontent:', error);
      alert('Failed to update subcontent. Please try again later.');
    }
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (!subContentData) {
    return <Typography>Error loading subcontent.</Typography>;
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Update Subcontent
      </Typography>
      <Box component="form" noValidate autoComplete="off">
        <TextField
          label="Subcontent Title"
          value={subContentTitle}
          onChange={(e) => setSubContentTitle(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Subcontent Description"
          value={subContentDescription}
          onChange={(e) => setSubContentDescription(e.target.value)}
          fullWidth
          margin="normal"
          multiline
        />
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={handleUpdateSubContent}>
            Update Subcontent
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default UpdateSubContentPage;
