import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Container, TextField, Button, Typography, Box, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import loginimage from '../images/b111.png';

interface IFormInput {
  name: string;
  password: string;
}

const Login: React.FC = () => {
  const { register, handleSubmit, formState: { errors } } = useForm<IFormInput>();
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState<string | null>(null);

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    console.log('Form data:', data); 

    // Trim whitespace from name and password
    const trimmedName = data.name.trim();
    const trimmedPassword = data.password.trim();

    try {
      const response = await fetch('https://cpca-api.midastix.com/admin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: trimmedName,
          password: trimmedPassword,
        }),
      });

      console.log('Response status:', response.status);

      const result = await response.json();
      console.log('Response data:', result);

      // Check if the response is not ok or the success message is not present
      if (!response.ok || result.result !== 'USER IS SUCCESSFULLY LOGGED IN') {
        console.error('Login failed:', result.result);
        // Set login error message based on the server response
        throw new Error(result.result || 'Login failed'); 
      }

      // Assuming successful response means login is valid
      localStorage.setItem('loginKey', '11112222');
      navigate('/bloglist');
    } catch (error) {
      console.error('Error:', error);

      // Type guard to handle the error as an instance of Error
      if (error instanceof Error) {
        setLoginError(error.message || 'Login failed. Please check your username and password.');
      } else {
        // Fallback error handling
        setLoginError('An unknown error occurred.');
      }
    }
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${loginimage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Container maxWidth="xs">
        <Box
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            padding: '2rem',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          {loginError && (
            <Alert severity="error" sx={{ mt: 2, width: '100%' }}>
              {loginError}
            </Alert>
          )}
          <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Username"
              {...register('name', { required: 'Username is required' })}
              error={!!errors.name}
              helperText={errors.name ? errors.name.message : ''}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Password"
              type="password"
              {...register('password', { required: 'Password is required' })}
              error={!!errors.password}
              helperText={errors.password ? errors.password.message : ''}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button>
          </Box>
        </Box>
      </Container>
    </Box> 
  );
};

export default Login;
