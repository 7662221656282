import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import bedbug_image from '../../images/termite2.png'

const BedBugControl = () => {
  

  return (
    <Grid container spacing={2} sx={{ marginTop: 12, backgroundColor: '#f5f5f5', alignItems: 'center' }}>
      <Grid item md={11} xs={11} sx={{ textAlign: 'left', margin: 'auto', position: 'relative', paddingBottom: '12px' }}>
        <Typography variant='h4' sx={{ marginBottom: '12px', paddingBottom: '4px', position: 'relative', zIndex: 1 , fontSize:{xs:'20px',sm:'28px'} }}>
          BED BUG CONTROL SERVICES
        </Typography>
        <Box sx={{ 
          content: '""', 
          position: 'absolute', 
          bottom: 0, 
          left: 0, 
          height: '2px', 
          width: '100%', 
          background: 'linear-gradient(to right, red 3cm, black 3cm)' 
        }} />
      </Grid>
      <Grid item xs={12} md={5}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <img src={bedbug_image} alt="Sample" style={{ maxWidth: '80%', height: '200px', borderRadius: '6px' }} />
        </Box>
      </Grid>
      <Grid item md={5} xs={10} sx={{margin:'0 auto'}}>
        <Typography 
           sx={{ 
            fontSize: {xs:'10px' ,md:'16px' ,sm:'24px'} ,
            paddingBottom:5 
          }}>
          If you have bed bugs in your house, the only thing on your mind will be getting rid of them as quickly as possible by getting well-organized bed bugs control services & Treatment. Bed bugs stay close to a food source, so are found where people tend to rest and sleep, hence the name bed bug. The frustration they can cause is not only due to the emotional stress of dealing with parasites, but also the irritation of their bites and the potential for secondary infection from constant scratching. Thus bed bugs treatment requires stringent efforts to remove them completely from your place. <br /><br />
          There has been a recent resurgence of bed bugs in homes biting people at night. It is widely thought that this is related to a global increase in international travel (bed bugs are known to travel in luggage or clothing). Raj Pest Control understand how distressing bed bugs can be. Call us free and we’ll get rid of your problem.We have been consistently providing highly effective bed bugs control services in Gurgaon, Noida, Pune, Navi Gurgaon and Goa. Our offered bed bugs removal services are highly appreciated by the clients. We have successfully provided our bed bugs treatment in Gurgaon all most major localities including Churchgate, Marine Lines, Charni Road, Grant Road, Gurgaon Central, Mahalaxmi, Lower Parel, Dadar, Matunga Road etc.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default BedBugControl;
