import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Box, Typography, Grid, Card, CardMedia, Breadcrumbs, TextField, Button, List, ListItem, ListItemText, CardContent } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import { Link as ScrollLink, Element } from 'react-scroll';

interface Blog {
  id: number;
  blogSubtitle: string;
  blogTitle: string;
  blogDescription: string;
  blogAuthor: string;
  blogThumbnail: string;
  date: string;
  content: string[];
}

export interface Content {
  id: number;
  content: string;
  contentImg: string | null;
  order: number;
  contentTitle: string;
  blog: Blog[];
}

interface Subcontent {
  id: number;
  contentId: number;
  subContentTitle: string;
  subContentDescription: string;
}

interface Comment {
  author: string;
  commentBody: string;
  status: boolean;
}


const BlogPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [blog, setBlog] = useState<Blog | null>(null);
  const [contents, setContents] = useState<Content[]>([]);
  const [subContents, setSubContents] = useState<{ [key: number]: Subcontent[] }>({});
  const [author, setAuthor] = useState<string>('');
  const [commentBody, setCommentBody] = useState<string>('');
  const [comments, setComments] = useState<Comment[]>([]);
  const contentRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        const response = await fetch(`https://cpca-api.midastix.com/blog/${id}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data: Blog = await response.json();
        setBlog(data);
      } catch (error) {
        console.error('Error fetching blog details:', error);
      }
    };

    const fetchContentDetails = async () => {
      try {
        const response = await fetch(`https://cpca-api.midastix.com/blog/${id}/content`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data: Content[] = await response.json();
        data.sort((a, b) => a.order - b.order);
        setContents(data);

        data.forEach((content) => {
          fetchSubContentDetails(content.id);
        });
      } catch (error) {
        console.error('Error fetching content details:', error);
      }
    };

    const fetchSubContentDetails = async (contentId: number) => {
      try {
        const response = await fetch(`https://cpca-api.midastix.com/blog/${id}/content/${contentId}/subContent`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data: Subcontent[] = await response.json();
        setSubContents((prev) => ({
          ...prev,
          [contentId]: data,
        }));
      } catch (error) {
        console.error('Error fetching subcontent details:', error);
      }
    };

    const fetchComments = async () => {
      try {
        const response = await fetch(`https://cpca-api.midastix.com/blog/${id}/comment`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data: Comment[] = await response.json();
        setComments(data);
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    };

    fetchBlogDetails();
    fetchContentDetails();
    fetchComments();
  }, [id]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const response = await fetch(`https://cpca-api.midastix.com/blog/${id}/comment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          author,
          commentBody,
          status: true,
        }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setComments([...comments, data]);
      setAuthor('');
      setCommentBody('');
    } catch (error) {
      console.error('Error submitting comment:', error);
    }
  };

  const generateTableOfContents = () => {
    return contents.map((content, index) => (
      <Box key={content.id}>
        <Typography variant="h6" component="h4" sx={{ fontWeight: 'bold', mb: 1 }}>
          <ScrollLink
            to={`content-${content.id}`}
            smooth={true}
            duration={500}
            offset={-70}
            style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}
          >
            {`${index + 1}. ${content.contentTitle}`}
          </ScrollLink>
        </Typography>
        {subContents[content.id] && (
          <List>
            {subContents[content.id].map((subcontent, subIndex) => (
              <ListItem key={subcontent.id}>
                <ListItemText
                  primary={
                    <ScrollLink
                      to={`subcontent-${subcontent.id}`}
                      smooth={true}
                      duration={500}
                      offset={-70}
                      style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}
                    >
                      {`${index + 1}.${subIndex + 1} ${subcontent.subContentTitle}`}
                    </ScrollLink>
                  }
                />
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    ));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px', width: '100%' }}>
      <Box sx={{ flexGrow: 1, width: '100%' }}>
        {blog && (
          <Box>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
              sx={{
                marginLeft: '10px',
                display: 'flex',
                justifyContent: 'left'
              }}>
              <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                Home
              </Link>
              <Link to="/blogs" style={{ textDecoration: 'none', color: 'inherit' }}>
                Blogs
              </Link>
              <Typography color="textPrimary">{blog.blogSubtitle}</Typography>
            </Breadcrumbs>

            <Box>
              <Grid item xs={10} sx={{ marginLeft: '10px' }}>
                <Typography sx={{ fontSize: '16px', marginTop: '16px' }}>
                  Rating: 4.5/5
                </Typography>
                <Typography sx={{ fontSize: '16px' }}>
                  Author of {blog.blogTitle}: {blog.blogAuthor}
                </Typography>
              </Grid>
            </Box>

            <Box
              sx={{
                textAlign: 'center',
                marginTop: '20px',
                backgroundImage: `url(https://cpca-api.midastix.com/${blog.blogThumbnail})`,
                position: 'relative',
                height: '400px',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                '::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  zIndex: 1,
                },
              }}
            >
              <Typography
                variant="h2"
                component="h1"
                gutterBottom
                sx={{
                  color: 'White',
                  position: 'absolute',
                  bottom: '10px',
                  left: '20px',
                  zIndex: 1,
                }}
              >
                {blog.blogTitle}
              </Typography>

              <Typography
                variant="body2"
                sx={{ color: 'white', position: 'absolute', top: '10px', right: '20px' }}
              >
                <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                  <CalendarMonthRoundedIcon sx={{ mr: 0.5 }} />
                  Updated: {new Date(blog.date).toLocaleDateString()}
                </Box>
              </Typography>
            </Box>

            <Grid container justifyContent="left" sx={{ background: '#fedccc69', margin: '0 auto' }}>
              <Grid item sx={{ marginLeft: '20px', marginTop: '12px' }} xs={12}>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{
                    color: 'black',
                    fontSize: '18px',
                  }}
                >
                  {blog.blogDescription}
                </Typography>
              </Grid>
            </Grid>

            <Box
              sx={{
                width: '100%',
                maxWidth: '800px',
                padding: '20px',
                backgroundColor: '#29b39e4f',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
                marginTop: '16px',
                marginBottom: '16px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{ textAlign: 'center', marginBottom: '16px' }}
              >
                Table of Contents
              </Typography>
              {generateTableOfContents()}
            </Box>

            <Grid
              container
              spacing={2}
              sx={{
               
                margin: '0 auto',
                marginTop: '8px',
                width: '100%',
                paddingBottom: '16px',
              }}
            >
              {/* content starts here  */}
              {contents.map((content, index) => {
                const imageUrl = content.contentImg ? `https://cpca-api.midastix.com/${content.contentImg}` : null;
                const isVideo = imageUrl && /\.(mp4|webm|ogg)$/i.test(imageUrl);
                return (
                  <Grid item xs={10} sx={{margin:'0 auto'}} key={content.id} ref={(el) => (contentRefs.current[index] = el)}>
                    <Element name={`content-${content.id}`} />
                    <Typography
                      id={`${index + 1}. ${content.contentTitle}`}
                      variant="h3"
                      
                      gutterBottom
                      sx={{
                        color: 'black',
                        position: 'relative',
                        display: 'inline-block',
                        '&::after': {
                          content: '""',
                          position: 'absolute',
                          left: 0,
                          bottom: 0,
                          width: '100%',
                          height: '3px',
                          background: 'linear-gradient(to right, red 30%, black 40%)',
                          zIndex: -1,
                        },
                      }}
                    >
                      {content.contentTitle}
                    </Typography>
                    <Typography 
                       variant="body1" 
                       component="p" 
                       gutterBottom 
                       sx={{ 
                        color: 'black' ,
                        fontSize:'20px'
                        }}>
                      {content.content}
                    </Typography>

                    {imageUrl && (
                      <Box 
                         sx={{ 
                          marginTop: '10px',
                          display: 'flex',
                          justifyContent: 'center', 
                          alignItems: 'center',
                          width: '100%',
                          
                          }}>
                        {isVideo ? (
                          <video controls style={{ maxWidth: '100%', height: 'auto' }}>
                            <source src={imageUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <Box 
                            sx={{
                              
                                display: 'flex',
                                justifyContent: 'center', 
                                alignItems: 'center',
                                width: '100%',
                                marginTop: '10px', 
                             
                              }}> 
                            <CardMedia
                              component="img"
                              height="auto"
                              image={imageUrl}
                              alt={content.contentTitle}
                              sx={{ maxWidth: '50%', height: 'auto' }}
                            />

                          </Box>
                        )}
                      </Box>

                    )}
                    {/* subcontent starts here */}
                    {subContents[content.id] && (
                      <Box>
                        {subContents[content.id].map((subcontent, subIndex) => (
                          <Element key={subcontent.id} name={`subcontent-${subcontent.id}`}>
                            <Box 
                            sx={{ 
                              marginTop: '20px', 
                              padding: '10px', 
                              border: '1px solid #ccc', 
                              borderRadius: '4px' 
                            }}>
                              <Typography 
                              variant="h6"
                              id={`${index + 1}.${subIndex + 1} ${subcontent.subContentTitle}`}
                              >
                              {subcontent.subContentTitle}</Typography>
                              <Typography variant="body2">{subcontent.subContentDescription}</Typography>
                            </Box>
                          </Element>
                        ))}
                      </Box>
                    )}
                  </Grid>
                );
              })}
            </Grid>

            {/* Comment Form */}
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                marginTop: '20px',
                padding: '16px',
                border: '1px solid #ddd',
                borderRadius: '8px',
                width: '100%',
                maxWidth: '800px',
                backgroundColor: 'white',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{ textAlign: 'center', marginBottom: '16px' }}
              >
                Leave a Comment
              </Typography>
              <TextField
                label="Name"
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
                fullWidth
                required
                sx={{ marginBottom: '16px' }}
              />
              <TextField
                label="Comment"
                value={commentBody}
                onChange={(e) => setCommentBody(e.target.value)}
                fullWidth
                required
                multiline
                rows={4}
                sx={{ marginBottom: '16px' }}
              />
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Box>

            {/* Comments List */}
            <Box
              sx={{
                marginTop: '20px',
                padding: '16px',
                border: '1px solid #ddd',
                borderRadius: '8px',
                width: '100%',
                maxWidth: '800px',
                backgroundColor: 'white',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: '20px',
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{ textAlign: 'center', marginBottom: '16px' }}
              >
                Comments
              </Typography>
              {comments.map((comment, index) => (
                <Box
                  key={index}
                  sx={{
                    marginBottom: '16px',
                    padding: '8px',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    backgroundColor: 'white',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {comment.author}
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: '8px' }}>
                    {new Date().toLocaleDateString()}
                  </Typography>
                  <Typography variant="body2">{comment.commentBody}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BlogPage;
