import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

interface Blog {
  id: number;
  blogTitle: string;
  blogSubtitle: string;
  blogDescription: string;
  content: string[];
  date: string;
  tags: any[];
  blogThumbnail?: string;
}

const UpdateBlogPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [blog, setBlog] = useState<Blog | null>(null);
  const [blogTitle, setBlogTitle] = useState('');
  const [blogSubtitle, setBlogSubtitle] = useState('');
  const [blogDescription, setBlogDescription] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [userSecretKey, setUserSecretKey] = useState('CCBxZgzRIAwYmhx9');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await fetch(`https://cpca-api.midastix.com/blog/${id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch blog post');
        }
        const data = await response.json();
        setBlog(data);
        setBlogTitle(data.blogTitle);
        setBlogSubtitle(data.blogSubtitle);
        setBlogDescription(data.blogDescription);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blog post:', error);
        setLoading(false);
      }
    };

    fetchBlog();
  }, [id]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpdateBlog = async () => {
    if (!userSecretKey) {
      alert('Secret key is required to update the blog.');
      return;
    }

    if (!blog) {
      alert('Blog data is not available.');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('blogTitle', blogTitle);
      formData.append('blogSubtitle', blogSubtitle);
      formData.append('blogDescription', blogDescription);
      formData.append('secretKey', userSecretKey);

      if (file) {
        formData.append('file', file);
      }

      const response = await fetch(`https://cpca-api.midastix.com/blog/${id}`, {
        method: 'PUT',
        body: formData,
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`Server Error: ${errorText}`);
        throw new Error(`Failed to update blog: ${errorText}`);
      }

      alert('Blog updated successfully');
      navigate('/bloglist');
    } catch (error) {
      console.error('Error updating blog:', error);
      alert('Failed to update blog. Please try again later.');
    }
  };

  const handleNavigateContent = () => {
    navigate(`/updatecontent/${id}`, { state: { blogId: id } });
  };
  
  const handleNavigateSubcontent = () => {
    navigate(`/updatesubcontent`, { state: { blogId: id } });
  };
  

  if (loading) {
    return <Typography>Loading...</Typography>; 
  }

  if (!blog) {
    return <Typography>Error loading blog post.</Typography>;
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Update Blog Post
      </Typography>
      <Box component="form" noValidate autoComplete="off">
        <TextField
          label="Blog Title"
          value={blogTitle}
          onChange={(e) => setBlogTitle(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Blog Subtitle"
          value={blogSubtitle}
          onChange={(e) => setBlogSubtitle(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Blog Description"
          value={blogDescription}
          onChange={(e) => setBlogDescription(e.target.value)}
          fullWidth
          margin="normal"
          multiline
        />
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="file-input"
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="file-input">
          <Button variant="contained" color="primary" component="span">
            Upload Thumbnail
          </Button>
        </label>
        <TextField
          label="Secret Key"
          value={userSecretKey}
          onChange={(e) => setUserSecretKey(e.target.value)}
          fullWidth
          margin="normal"
          type="password"
        />
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={handleUpdateBlog}>
            Update Blog
          </Button>
        </Box>
        <Box mt={2}>
          <Button variant="outlined" color="secondary" onClick={handleNavigateContent}>
            Update Content
          </Button>
        </Box>
        <Box mt={2}>
          <Button variant="outlined" color="secondary" onClick={handleNavigateSubcontent}>
            Update Subcontent
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default UpdateBlogPage;
