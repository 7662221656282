
import React, { useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import backimage from '../../images/b2.png';
import Footer from '../footer';
import GlobalTeam from '../global';
import GetQuote from '../freequote';
import { Link } from 'react-router-dom';
import Flysrevice from './flycontrol_segment';
import Flycontrol from './flycontrol';


const Fly: React.FC = () => {
    useEffect(() => {

        window.scrollTo(0, 0);
    }, []);
    return (

        <Box>
            
            <Box
                sx={{
                    position: 'relative',
                    backgroundImage: `url(${backimage})`,
                    backgroundSize: 'cover',
                    height: {sm:'50vh' ,xs:'60vh'},
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    color: 'white',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                }}
            >
                <Grid container>
                    <Grid
                        item
                        xs={10}
                        md={9}
                        sx={{
                            position: 'relative',
                            zIndex: 1,
                            margin: '0 auto'
                        }}>
                        <Typography
                            variant="h2"
                            sx={{
                                mb: 2,
                                color: 'white',
                                textAlign: 'center',
                                fontSize: { xs: '28px', md: '40px' , sm:'56px' }
                            }}>
                         FLY CONTROL SERVICES
                        </Typography>

                        <Typography
                            variant="body1"
                            sx={{
                                color: 'white',
                                fontSize: {
                                    xs: '16px',
                                    md: '20px',
                                    sm: '28px'
                                },
                                textAlign: 'center'
                            }}>
                            We are kota based company who offers highly effective fly control services in the market. Our offered services include treatment & control for various types of flies like house fly, blow flies, fruit flies, drain flies etc.
                        </Typography>

                        <Button
                            component={Link}
                            to="../freequote"
                            variant="contained"
                            sx={{
                                background: 'yellow',
                                color: 'black',

                                width: {
                                    xs: '150px',
                                    md: '150px',
                                    sm: '150px'
                                },
                                minHeight: '60px',
                                margin: 1,
                                padding: '10px',
                                borderRadius: '30px',
                                '&:hover': {
                                    background: 'lightyellow',
                                    fontWeight: 'bold',
                                },
                                '& .MuiTypography-root': {
                                    flexGrow: 1,
                                    textAlign: 'center',
                                },
                                '& .MuiButton-startIcon': {
                                    marginRight: '8px',
                                },
                                '& .MuiButton-endIcon': {
                                    marginLeft: '6px',
                                },
                            }}
                        >
                            <Typography
                                variant="body1"
                                color={'black'}
                                sx={{
                                    fontSize: {
                                        xs: '10px',
                                    },
                                }}>
                                Request an appointment
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>


            </Box>

            <Flysrevice/>
            <Flycontrol/>
            <GlobalTeam />
            <GetQuote />
            <Footer />

        </Box>
    );
};

export default Fly;
