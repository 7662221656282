import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, Box } from '@mui/material';

interface SubContent {
  subContentTitle: string;
  subContentDescription: string;
}

const SubContentForm: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { blogId, contentIds } = location.state as { blogId: number; contentIds: number[] };

  const [subContents, setSubContents] = useState<{ [key: number]: SubContent[] }>(
    contentIds.reduce((acc, id) => ({ ...acc, [id]: [] }), {})
  );

  const handleSubContentChange = (contentId: number, index: number, key: string, value: string) => {
    const newSubContents = { ...subContents };
    newSubContents[contentId][index] = { ...newSubContents[contentId][index], [key]: value };
    setSubContents(newSubContents);
  };

  const addSubContentField = (contentId: number) => {
    const newSubContents = { ...subContents };
    newSubContents[contentId] = [...newSubContents[contentId], {
      subContentTitle: '',
      subContentDescription: ''
    }];
    setSubContents(newSubContents);
  };

  const removeSubContentField = (contentId: number, index: number) => {
    const newSubContents = { ...subContents };
    newSubContents[contentId] = newSubContents[contentId].filter((_, i) => i !== index);
    setSubContents(newSubContents);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      await Promise.all(
        Object.entries(subContents).map(async ([contentId, subContentArray]) => {
          await Promise.all(subContentArray.map(async (subContent) => {
            const subContentPayload = {
              subContentTitle: subContent.subContentTitle,
              subContentDescription: subContent.subContentDescription
            };

            const subContentUrl = `https://cpca-api.midastix.com/blog/${blogId}/content/${contentId}/subContent`;

            const subContentResponse = await fetch(subContentUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(subContentPayload),
            });

            if (!subContentResponse.ok) {
              const errorText = await subContentResponse.text();
              console.error(`Failed to post subcontent: ${subContentResponse.statusText} - ${errorText}`);
              throw new Error(`Failed to post subcontent: ${subContentResponse.statusText}`);
            }

            return subContentResponse.json();
          }));
        })
      );

      navigate('/bloglist');
    } catch (error) {
      console.error('Error posting subcontent:', error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Add Subcontents
      </Typography>
      <form onSubmit={handleSubmit}>
        {Object.entries(subContents).map(([contentId, subContentArray]) => (
          <Box key={contentId} mt={2} border={1} borderColor="grey.400" p={2}>
            <Typography variant="h6">Content #{contentId}</Typography>
            {subContentArray.map((subContent, index) => (
              <Box key={index} mt={2} border={1} borderColor="grey.300" p={2}>
                <TextField
                  label="Subcontent Title"
                  fullWidth
                  margin="normal"
                  value={subContent.subContentTitle}
                  onChange={(e) => handleSubContentChange(Number(contentId), index, 'subContentTitle', e.target.value)}
                />
                <TextField
                  label="Subcontent Description"
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                  value={subContent.subContentDescription}
                  onChange={(e) => handleSubContentChange(Number(contentId), index, 'subContentDescription', e.target.value)}
                />
                <Button onClick={() => removeSubContentField(Number(contentId), index)} color="secondary">
                  Remove Subcontent
                </Button>
              </Box>
            ))}
            <Button onClick={() => addSubContentField(Number(contentId))} variant="contained" color="primary">
              Add Subcontent
            </Button>
          </Box>
        ))}
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </form>
    </Container>
  );
};

export default SubContentForm;
