import React, { useEffect, useState } from 'react';
import { Box, Typography, List, ListItem, ListItemText, IconButton, Divider, Radio, FormControlLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams } from 'react-router-dom';

interface Comment {
  id: number;
  author: string;
  commentBody: string;
  status: boolean;
}

interface Blog {
  id: number;
  title: string;
  
}

const AdminPage: React.FC = () => {
  const { blogId } = useParams<{ blogId: string }>();
  const [comments, setComments] = useState<Comment[]>([]);
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [selectedBlogId, setSelectedBlogId] = useState<string | undefined>(blogId);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch(`https://cpca-api.midastix.com/blog`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data: Blog[] = await response.json();
        setBlogs(data);

       
        data.forEach(blog => console.log(`Blog ID: ${blog.id}`));

        
        if (!selectedBlogId && data.length > 0) {
          setSelectedBlogId(data[0].id.toString());
        }
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, [selectedBlogId]);

  useEffect(() => {
    if (selectedBlogId) {
      console.log('Selected Blog ID:', selectedBlogId);
      const fetchComments = async () => {
        try {
          const response = await fetch(`https://cpca-api.midastix.com/blog/${selectedBlogId}/comment`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data: Comment[] = await response.json();
          setComments(data);
        } catch (error) {
          console.error('Error fetching comments:', error);
        }
      };

      fetchComments();
    }
  }, [selectedBlogId]);

  const handleDelete = async (commentId: number) => {
    try {
      const response = await fetch(`https://cpca-api.midastix.com/blog/${selectedBlogId}/comment/${commentId}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setComments(comments.filter(comment => comment.id !== commentId));
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  const handleStatusChange = async (commentId: number, newStatus: boolean) => {
    try {
      const response = await fetch(`https://cpca-api.midastix.com/blog/${selectedBlogId}/comment/${commentId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: newStatus }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setComments(comments.map(comment =>
        comment.id === commentId ? { ...comment, status: newStatus } : comment
      ));
    } catch (error) {
      console.error('Error updating comment status:', error);
    }
  };

  return (
    <Box sx={{ padding: '20px', maxWidth: '1200px', margin: 'auto' }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Admin Panel - Manage Comments
      </Typography>
      <Divider />
      <List>
        {comments.map(comment => (
          <ListItem key={comment.id} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <ListItemText
              primary={comment.author}
              secondary={comment.commentBody}
              sx={{ color: 'black' }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                control={
                  <Radio
                    checked={comment.status}
                    onChange={() => handleStatusChange(comment.id, true)}
                  />
                }
                label="Published"
                sx={{ marginRight: '10px' }}
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={!comment.status}
                    onChange={() => handleStatusChange(comment.id, false)}
                  />
                }
                label="Unpublished"
              />
              <IconButton onClick={() => handleDelete(comment.id)} aria-label="delete">
                <DeleteIcon color="error" />
              </IconButton>
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default AdminPage;
