import React from 'react';
import { Container, Typography, Box, Grid, Paper, Avatar, Button, IconButton, List, ListItem, ListItemText } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import InstagramIcon from '@mui/icons-material/Instagram';
import { styled } from '@mui/system';
import imagep from '../images/profile.png'

const HeroSection = styled('div')({
  background: 'linear-gradient(135deg, #6a11cb 10%, #2575fc 100%)',
  color: '#fff',
  padding: '80px 0',
  textAlign: 'center',
  marginBottom: '40px',
});

const AvatarStyled = styled(Avatar)({
  width: '150px',
  height: '150px',
  margin: '0 auto', 
  marginBottom: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center', 
});

const SocialIcons = styled(Box)({
  '& > *': {
    margin: '0 8px',
    color: '#fff',
  },
});

const Section = styled('div')({
  padding: '40px 0',
  textAlign: 'center',
});

const SectionTitle = styled(Typography)({
  marginBottom: '40px',
});

const PaperStyled = styled(Paper)({
  padding: '20px',
  minHeight: '200px',
  marginBottom: '20px',
  textAlign: 'left',
});

const PortfolioItem = styled(Grid)({
  marginBottom: '30px',
});

const ListStyled = styled(List)({
  textAlign: 'left',
  margin: '20px 0',
});

const PortfolioPage = () => {
  return (
    <Box>
      <HeroSection>
        <Container maxWidth="md">
          <AvatarStyled alt="John Doe" src={imagep} />
          <Typography variant="h4" component="h1" gutterBottom>
            Dr. Gautam Singh Dhaked
          </Typography>
          <Typography variant="h6" gutterBottom>
            Founder of CPCA
          </Typography>
          <SocialIcons>
            <IconButton aria-label="LinkedIn" href="#">
              <LinkedInIcon />
            </IconButton>
            <IconButton aria-label="GitHub" href="#">
              <GitHubIcon />
            </IconButton>
            <IconButton aria-label="Instagram" href="#">
              <InstagramIcon />
            </IconButton>
          </SocialIcons>
        </Container>
      </HeroSection>

      <Section color="#f0f0f0">
        <Container maxWidth="md">
          <SectionTitle variant="h3" gutterBottom>
            About Me
          </SectionTitle>
          <PaperStyled elevation={3}>
            <Typography sx={{mt:3,fontSize:'20px'}}>
              With a strong passion for Agronomy, I am dedicated to advancing in the fields of Research & Development, teaching, and extension services. My goal is to contribute to the organization's success through my best efforts, aligning with its objectives and values. I am committed to continuous learning and professional growth, striving to enhance my knowledge, skills, and capabilities with full dedication and sincerity.
            </Typography>
          </PaperStyled>
        </Container>
      </Section>

      {/* Experience Section */}
      <Section>
        <Container maxWidth="md">
          <SectionTitle variant="h3" gutterBottom>
            Experience
          </SectionTitle>
          <Grid container spacing={3}>
            {/* Replace with your experience items */}
            <PortfolioItem item xs={12} md={4}>
              <PaperStyled elevation={3}>
                <Typography variant="h5" gutterBottom sx={{fontWeight:'bolder'}}>
                  NAAC Accreditation (2018 & 2024)
                </Typography>
                <Typography>
                  Managed comprehensive documentation and presentation processes for the National Assessment and Accreditation Council (NAAC) accreditation for both the 2018 and 2024 cycles.
                </Typography>
              </PaperStyled>
            </PortfolioItem>
            <PortfolioItem item xs={12} md={4}>
              <PaperStyled elevation={3}>
                <Typography variant="h5" gutterBottom sx={{fontWeight:'bolder'}}>
                  SSR Preparation for ICAR Accreditation
                </Typography>
                <Typography>
                  Authored and compiled the Self-Study Report (SSR) for Indian Council of Agricultural Research (ICAR) accreditation.
                </Typography>
              </PaperStyled>
            </PortfolioItem>
            <PortfolioItem item xs={12} md={4}>
              <PaperStyled elevation={3}>
                <Typography variant="h5" gutterBottom sx={{fontWeight:'bolder'}}>
                  UGC and State Government Inspections
                </Typography>
                <Typography>
                  Handled documentation and preparations for inspections by the University Grants Commission (UGC) and state government agencies, including the Joint Entrance Test (JET).
                </Typography>
              </PaperStyled>
            </PortfolioItem>
          </Grid>
        </Container>
      </Section>

      {/* Portfolio Section */}
      <Section color="#f0f0f0">
        <Container maxWidth="md">
          <SectionTitle variant="h3" gutterBottom>
            Portfolio
          </SectionTitle>
          <Grid container spacing={3}>
            {/* Replace with your portfolio items */}
            <PortfolioItem item xs={12} md={4}>
              <PaperStyled elevation={3}>
                <Typography variant="h6" gutterBottom>
                  Project 1
                </Typography>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero at purus.
                </Typography>
                <Button variant="contained" color="primary" href="#">
                  View Project
                </Button>
              </PaperStyled>
            </PortfolioItem>
            <PortfolioItem item xs={12} md={4}>
              <PaperStyled elevation={3}>
                <Typography variant="h6" gutterBottom>
                  Project 2
                </Typography>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero at purus.
                </Typography>
                <Button variant="contained" color="primary" href="#">
                  View Project
                </Button>
              </PaperStyled>
            </PortfolioItem>
            <PortfolioItem item xs={12} md={4}>
              <PaperStyled elevation={3}>
                <Typography variant="h6" gutterBottom>
                  Project 3
                </Typography>
                <Typography>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis libero at purus.
                </Typography>
                <Button variant="contained" color="primary" href="#">
                  View Project
                </Button>
              </PaperStyled>
            </PortfolioItem>
          </Grid>
        </Container>
      </Section>

      {/* Papers Published Section */}
      <Section>
        <Container maxWidth="md">
          <SectionTitle variant="h3" gutterBottom>
            Papers Published
          </SectionTitle>
          <PaperStyled elevation={3}>
            <ListStyled>
              <ListItem>
                <ListItemText
                  primary={<span>Dhaker, R.C., Dubey, R. K., <strong>Dhakad, G.S.</strong>, Mishra, Shubham, Meena, B.K. and Kumar, D. (2016). <em>Effect on plant height, LAI, and various yield attributes of fenugreek (Trigonella foenum-graecum L.) under varying IW-CPE ratios and fertilizer levels in Rajasthan.</em> Abstract published in National Conference FABCP-2016. NAAS.</span>}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<span>Amar Kant Verma<sup>*</sup>, R.P. Singh<sup>*</sup>, <strong>Gautam Singh Dhaked<sup>**</sup></strong>, U.D. Awasthi<sup>*</sup>, P.N. Yadav<sup>*</sup> and Naval Kishor<sup>*</sup> (2018). <em>Productivity of Rain Water Harvesting Techniques on Production Efficiency, Leaf Area Index and Moisture Use Rate of Pearl millet (Pennisetum glaucum L.) Under Rainfed Condition.</em> International Journal of Current Microbiology and Applied Sciences (NAAS RATING 5.38).</span>}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<span>Amar Kant Verma<sup>1</sup>, P.N. Yadav<sup>2</sup>, U.D. Awasthi<sup>3</sup>, R.K. Pathak<sup>4</sup>, Rahul Ranjan<sup>5</sup>, and <strong>Gautam Singh Dhaked<sup>6</sup></strong> (2019). <em>Response of root development and quality parameters on linseed (Linum Usitatissimum L.) based intercropping systems as influenced by integrated nutrient management under rainfed condition.</em> Bulletin of Environment, Pharmacology and Life Sciences (NAAS RATING 4.95).</span>}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<span><strong>Gautam Singh Dhaked<sup>1</sup></strong> and Amar Kant Verma<sup>2</sup> (2019). <em>Organic Farming: Agriculture Technology, Sustainability, Status, Issues and Prospects – A Review.</em> International Journal of Current Microbiology and Applied Sciences ISSN: 2319-7706 Volume 8 Number 06 (2019).</span>}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={<span>Vijay Upadhyay<sup>*</sup>, Abhishek Raj<sup>1</sup>, Brajesh K. Yadav<sup>2</sup>, and <strong>Gautam Singh Dhakad<sup>2</sup></strong> (2019). <em>Study of Temperature Effect on Gharial Habitat at National Chambal Gharial Wildlife Sanctuary Uttar Pradesh, India.</em> International Journal of Chemical Studies P-ISSN: 2349-8528, E-ISSN: 2321-4902.</span>}
                />
              </ListItem>
            </ListStyled>
          </PaperStyled>
        </Container>
      </Section>

      {/* Contact Section */}
      <Section color="#f0f0f0">
        <Container maxWidth="md">
          <SectionTitle variant="h3" gutterBottom>
            Contact Me
          </SectionTitle>
          <PaperStyled elevation={3}>
            <Typography>
              <strong>Email:</strong> gautamsinghdhaked@gmail.com
              <br />
              <strong>Phone:</strong> +91-9456486286
              <br />
              <strong>Location:</strong> Kota, India
            </Typography>
            <Button variant="contained" color="primary" href="mailto:example@email.com">
              Get in Touch
            </Button>
          </PaperStyled>
        </Container>
      </Section>
    </Box>
  );
};

export default PortfolioPage;
