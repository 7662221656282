import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import bugimage from "../images/justin-lauria-Ap0alm8xpxw-unsplash.jpg"
import OurServices from './ourservises';
import GetQuote from './freequote';
import Usp from './usp';
import GlobalTeam from './global';
import Footer from './footer';
import LazyHomeBannerWrapper from './lasyhomebanner';
import Lcsegment from './lc';
import TraininggalleryMain from './trainingmain';


function Home() {
    
    return (
        <Box>
           
            
            <LazyHomeBannerWrapper />
            
            <Grid container spacing={1} sx={{ marginTop: 10 }}>
                <Grid item md={5} xs={12}
                    sx={{ margin: '0 auto' }}>
                    <Typography variant='h2' sx={{ color: '#001457', fontSize: '38px', fontWeight: 600 , textAlign:'center'}}>
                        What makes us Different
                    </Typography>
                </Grid>

            </Grid>
            <Grid container spacing={2} sx={{ marginTop: 5, }}>
                <Grid item md={6} xs={10}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0 auto',
                        alignContent: 'center',
                        textAlign: 'center'
                    }}
                >
                    <img src="https://www.nubornpest.com/assets/img/service/icon-1.jpg" alt="trust" height={80} width={80} />
                    <Typography sx={{ fontSize: '30px', color: '#001457', }}>
                        Local expertise, global resources
                    </Typography>
                    <Typography sx={{ color: '#001457', }}>
                        With specialized services based on your climate and local pest problems.
                    </Typography>
                </Grid>

                <Grid item md={6} xs={10}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '0 auto',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                        textAlign: 'center'
                    }}
                >
                    <img src='https://www.nubornpest.com/assets/img/service/icon-2.jpg' alt="trust" height={80} width={80} />
                    <Typography sx={{
                        color: '#001457',
                        fontSize: '30px'
                    }}>
                        Locally Owned Business
                    </Typography>
                    <Typography sx={{ color: '#001457', }}>
                        We take pride in serving our local community. It's where we work, live, and raise our families.


                    </Typography>
                </Grid>
                <Grid item md={6} xs={10}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '0 auto',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                        textAlign: 'center'
                    }}
                >
                    <img src='https://www.nubornpest.com/assets/img/service/icon-4.jpg' alt="trust" height={80} width={80} />
                    <Typography sx={{
                        fontSize: '30px',
                        color: '#001457',
                    }}>
                        Local expertise, global resources
                    </Typography>
                    <Typography sx={{ color: '#001457', }}>
                        With specialized services based on your climate and local pest problems.
                    </Typography>
                </Grid>
                <Grid item md={6} xs={10}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '0 auto',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                        textAlign: 'center'
                    }}
                >
                    <img src="https://www.nubornpest.com/assets/img/service/icon-3.jpg" alt="trust" height={80} width={80} />
                    <Typography sx={{
                        fontSize: '30px',
                        color: '#001457',
                    }}>
                        24-Hour Guarantee
                    </Typography>
                    <Typography sx={{ color: '#001457', }}>
                        Help is just a call or click away! We guarantee to solve the pest problem in your home or business within 24 hours.
                    </Typography>
                </Grid>

            </Grid>
            <Grid container
                sx={{
                    marginTop: 15,
                    position: 'relative',
                    backgroundImage: `url(${bugimage})`,
                    backgroundSize: 'contain',
                    height: '40vh',
                    width: "100%",
                    display: 'flex',
                    alignItems: 'top',
                    justifyContent: 'left',
                    color: 'white',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.66)',
                        zIndex: 1,
                    }
                }}
            >
                <Grid item sx={{
                    position: 'relative',
                    zIndex: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0 auto',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                    textAlign: 'center'
                }}>
                    <Typography variant='h3' fontWeight={400} sx={{fontSize:{xs:'30px'}}}>
                        Protecting Your Home and Business <br /> from unwanted guests
                    </Typography>
                </Grid>
            </Grid>
            <OurServices />
            <Usp />
            <TraininggalleryMain/>
            <Lcsegment/>
            <GlobalTeam />
            <GetQuote />
            <Footer />



        </Box>
    );
    
}

export default Home;
